/* eslint-disable no-undef */
import axios from "axios";

const axiosMain = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axiosMain.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      //   logout
      setTimeout(() => {
        localStorage.clear();
        window.location = "/";
      }, 1000);

      return Promise.reject();
    }
    return error.response;
  }
);

export default axiosMain;
