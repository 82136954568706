/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
import { put } from "redux-saga/effects";
import errorHandler from "utils/errorHandler";
import toaster from "utils/toaster";
import { USER_SUBDOMIN } from "constants/env";
import {
  getFutureProjectsStart,
  getFutureProjectsSuccess,
  getFutureProjectsFail,
  getLiveProjectsStart,
  getLiveProjectsSuccess,
  getLiveProjectsFail,
  getPublicConsultationProjectsStart,
  getPublicConsultationProjectsSuccess,
  getPublicConsultationProjectsFail,
  getInvestorDashboardProjectStart,
  getInvestorDashboardProjectSuccess,
  getInvestorDashboardProjectFail,
} from "store/actions";
import { getDashboardProjectStart } from "store/actions";
import { getDashboardProjectSuccess } from "store/actions";
import { getDashboardProjectFail } from "store/actions";

// future projects
export function* getFutureProjectsSaga({ payload }) {
  yield put(getFutureProjectsStart());
  const { standard, methodology, sector, itemsPerPage, search, country } =
    payload;

  // Construct the base endpoint string
  let endpoint = `/marketplace/get-forward-projects?`;

  // Check if standard is not empty and add it to the endpoint
  if (standard) {
    endpoint += `_standard=${standard}`;
  }

  // Check if methodology is not empty and add it to the endpoint
  if (methodology) {
    endpoint += `&_methodology=${methodology}`;
  }

  // Check if sector is not empty and add it to the endpoint
  if (sector) {
    endpoint += `&_sector=${sector}`;
  }

  // Check if search is not empty and add it to the endpoint
  if (search) {
    endpoint += `&search=${search}`;
  }

  // Check if country is not empty and add it to the endpoint
  if (country) {
    endpoint += `&country=${country}`;
  }

  // Add startIndex and itemsPerPage to the endpoint
  endpoint += `&startIndex=1&itemsPerPage=${itemsPerPage}`;

  yield errorHandler({
    // endpoint: `/marketplace/get-future-projects?_standard=${standard}?&_sector=${sector}?&_methodology=${methodology}`,
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onFutureProjectsSuccess(response) {
      const { data } = response;
      yield put(getFutureProjectsSuccess({ data, response }));
    },
    failHandler: yield function* onFutureProjectsFail(response) {
      yield put(getFutureProjectsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// live projects
export function* getLiveProjectsSaga({ payload }) {
  yield put(getLiveProjectsStart());
  const {
    standard,
    methodology,
    sector,
    itemsPerPage,
    search,
    country,
    vintageYear,
  } = payload;

  // Construct the base endpoint string
  let endpoint = `/marketplace/get-live-projects?`;

  // Check if standard is not empty and add it to the endpoint
  if (standard) {
    endpoint += `_standard=${standard}`;
  }

  // Check if methodology is not empty and add it to the endpoint
  if (methodology) {
    endpoint += `&_methodology=${methodology}`;
  }

  // Check if sector is not empty and add it to the endpoint
  if (sector) {
    endpoint += `&_sector=${sector}`;
  }

  // Check if search is not empty and add it to the endpoint
  if (search) {
    endpoint += `&search=${search}`;
  }

  // Check if vintageYear is not empty and add it to the endpoint
  if (vintageYear) {
    endpoint += `&vintageYear=${vintageYear}`;
  }

  // Check if country is not empty and add it to the endpoint
  if (country) {
    endpoint += `&country=${country}`;
  }

  // Add startIndex and itemsPerPage to the endpoint
  endpoint += `&startIndex=1&itemsPerPage=${itemsPerPage}`;

  yield errorHandler({
    // endpoint: `/marketplace/get-live-projects?_standard=${standard}?&_sector=${sector}?&_methodology=${methodology}`,
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onLiveProjectsSuccess(response) {
      const { data } = response;
      yield put(getLiveProjectsSuccess({ data, response }));
    },
    failHandler: yield function* onLiveProjectsFail(response) {
      yield put(getLiveProjectsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// public consultation projects
export function* getPublicConsultationProjectsSaga({ payload }) {
  yield put(getPublicConsultationProjectsStart());
  const { standard, methodology, sector, itemsPerPage, search, country } =
    payload;

  // Construct the base endpoint string
  let endpoint = `/marketplace/get-public-consultation-projects?`;

  // Check if standard is not empty and add it to the endpoint
  if (standard) {
    endpoint += `_standard=${standard}`;
  }

  // Check if methodology is not empty and add it to the endpoint
  if (methodology) {
    endpoint += `&_methodology=${methodology}`;
  }

  // Check if sector is not empty and add it to the endpoint
  if (sector) {
    endpoint += `&_sector=${sector}`;
  }

  // Check if search is not empty and add it to the endpoint
  if (search) {
    endpoint += `&search=${search}`;
  }

  // Check if country is not empty and add it to the endpoint
  if (country) {
    endpoint += `&country=${country}`;
  }

  // Add startIndex and itemsPerPage to the endpoint
  endpoint += `&startIndex=1&itemsPerPage=${itemsPerPage}`;

  yield errorHandler({
    // endpoint: `/marketplace/get-public-consultation-projects?_standard=${standard}?&_sector=${sector}?&_methodology=${methodology}`,
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onPublicConsultationProjectsSuccess(
      response
    ) {
      const { data } = response;
      yield put(getPublicConsultationProjectsSuccess({ data, response }));
    },
    failHandler: yield function* onPublicConsultationProjectsFail(response) {
      yield put(getPublicConsultationProjectsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

export function* getDashboardProjectsSaga({ payload }) {
  const { name, itemsPerPage, startIndex = 1, search = "" } = payload;

  const routeObj = {
    draft: "draft-projects",
    seed: "seed-projects",
    publish: "registered-projects",
    offer: "my-offers",
  };
  if (!routeObj[name]) return;
  yield put(getDashboardProjectStart());

  let endpoint = `/dashboard/get-${routeObj[name]}?`;
  endpoint += `startIndex=${startIndex}&itemsPerPage=${itemsPerPage}&search=${search}`;

  yield errorHandler({
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      // const { data } = response;
      yield put(getDashboardProjectSuccess({ data: response, name }));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardProjectFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* getInvestorDashboardProjectsSaga({ payload }) {
  const {
    name,
    itemsPerPage,
    startIndex = 1,
    search = "",
    vintageYear = "",
  } = payload;

  const routeObj = {
    fccHoldings: "my-holding-fcc-projects",
    vccHoldings: "my-holding-vcc-projects",
    listing: "my-listing-projects",
    retirements: "my-retirements",
    submitted: "my-submitted-offers",
    recieved: "my-received-offers",
  };

  if (!routeObj[name]) return;
  yield put(getInvestorDashboardProjectStart());

  let endpoint = `/dashboard/get-${routeObj[name]}?`;
  endpoint += `startIndex=${startIndex}&itemsPerPage=${itemsPerPage}&search=${search}`;

  // Add vintageYear if it's provided and the route is 'listing'
  if (name === "listing" && vintageYear) {
    endpoint += `&vintageYear=${vintageYear}`;
  }

  yield errorHandler({
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      // const { data } = response;
      yield put(getInvestorDashboardProjectSuccess({ data: response, name }));
    },
    failHandler: yield function* (response) {
      yield put(getInvestorDashboardProjectFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}
