import { HeaderBackground } from "constants/assets";

const headerStyle = (theme) => ({
  appBar: {
    background: `url(${HeaderBackground})`,
    height: "70px",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto",
    },
  },
  headerContainer: {
    // Overriding the default padding of mui container
    paddingLeft: "50px !important",
    margin: "0px",
    height: "100%",
    width: "100%",
    // Overriding the default maxWidth of mui container
    maxWidth: "none !important",
    [theme.breakpoints.down("sm")]: {
      // Overriding the default padding of mui container
      paddingLeft: "30px !important",
    },
  },
  logo: {
    cursor: "pointer",
  },
  height100: {
    height: "100%",
  },
  headerTitle: {
    marginLeft: "10px",
    fontFamily: "Candara",
    fontWeight: 700,
    color: "#ffffff",
    fontSize: "14px",
    textDecoration: "none",
    cursor: "pointer",
  },
  navLinksBox: {
    flexGrow: 1.5,
    // marginLeft: "60px",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
  },
  navLinkIcon: {
    height: "18px",
    width: "18px",
  },
  navLinkText: {
    color: "white",
    display: "block",
    marginLeft: "10px",
    marginRight: "35px",
    "&:hover": { color: "#58F2F0" },
  },
  actionsBox: {
    flexGrow: 0,
    position: "absolute",
    right: "40px",
    alignContent: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      right: "20px",
    },
  },
  bellIcon: {
    marginRight: "30px",
    cursor: "pointer",
  },
  iconButton: {
    p: 0,
    border: "1.6px solid #FFF",
    height: "34px",
    width: "34px",
  },
  profileAvatar: {
    background: "transparent",
    color: "#FFF",
    fontSize: "13px",
    fontWeight: "500",
  },
  dFlex: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  displaySM: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  displayLG: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  read: {
    fontSize: "14px",
    fontWeight: 700,
    margin: "0px",
    padding: "0px",
    minWidth: "unset",
    minHeight: "unset",
    lineHeight: "23.44px",
  },
  notificationItem: {
    fontSize: "16px",
    fontWeight: 500,
  },
  markAll: {
    width: "80%",
    fontSize: "14px",
    margin: "5px",
  },
  time: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#757575",
  },
  count: {
    position: "absolute",
    bottom: "22px",
    left: "-5px",
    backgroundColor: "white",
    borderRadius: "50%",
    // color: "#286C45",
    color: "#0D1F0B",
    height: "19px",
    width: "20px",
    padding: "2px",
    fontSize: "12px",
  },
  noRipple: {
    "&:hover": {
      cursor: "default",
      transition: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      PointerEvent: "none",
    },
  },
});

export default headerStyle;
