// future projects

export const GET_FUTURE_PROJECTS_SAGA = "GET_FUTURE_PROJECTS_SAGA";
export const GET_FUTURE_PROJECTS_START = "GET_FUTURE_PROJECTS_START";
export const GET_FUTURE_PROJECTS_SUCCESS = "GET_FUTURE_PROJECTS_SUCCESS";
export const GET_FUTURE_PROJECTS_FAIL = "GET_FUTURE_PROJECTS_FAIL";

// live projects

export const GET_LIVE_PROJECTS_SAGA = "GET_LIVE_PROJECTS_SAGA";
export const GET_LIVE_PROJECTS_START = "GET_LIVE_PROJECTS_START";
export const GET_LIVE_PROJECTS_SUCCESS = "GET_LIVE_PROJECTS_SUCCESS";
export const GET_LIVE_PROJECTS_FAIL = "GET_LIVE_PROJECTS_FAIL";

// Projects for Public Consultation

export const GET_PUBLIC_CONSULTATION_PROJECTS_SAGA =
  "GET_PUBLIC_CONSULTATION_PROJECTS_SAGA";
export const GET_PUBLIC_CONSULTATION_PROJECTS_START =
  "GET_PUBLIC_CONSULTATION_PROJECTS_START";
export const GET_PUBLIC_CONSULTATION_PROJECTS_SUCCESS =
  "GET_PUBLIC_CONSULTATION_PROJECTS_SUCCESS";
export const GET_PUBLIC_CONSULTATION_PROJECTS_FAIL =
  "GET_PUBLIC_CONSULTATION_PROJECTS_FAIL";

// Get dashboard projects
export const GET_DASHBOARD_PROJECT_SAGA = "GET_DASHBOARD_PROJECT_SAGA";
export const GET_DASHBOARD_PROJECT_SUCCESS = "GET_DASHBOARD_PROJECT_SUCCESS";
export const GET_DASHBOARD_PROJECT_START = "GET_DASHBOARD_PROJECT_START";
export const GET_DASHBOARD_PROJECT_FAIL = "GET_DASHBOARD_PROJECT_FAIL";

// Clear dashboard data
export const CLEAR_DASHBOARD_PROJECT = "CLEAR_DASHBOARD_PROJECT";

// Get investor dashboard projects
export const GET_INVESTOR_DASHBOARD_PROJECT_SAGA =
  "GET_INVESTOR_DASHBOARD_PROJECT_SAGA";
export const GET_INVESTOR_DASHBOARD_PROJECT_SUCCESS =
  "GET_INVESTOR_DASHBOARD_PROJECT_SUCCESS";
export const GET_INVESTOR_DASHBOARD_PROJECT_START =
  "GET_INVESTOR_DASHBOARD_PROJECT_START";
export const GET_INVESTOR_DASHBOARD_PROJECT_FAIL =
  "GET_INVESTOR_DASHBOARD_PROJECT_FAIL";

// Clear investor dashboard data
export const CLEAR_INVESTOR_DASHBOARD_PROJECT =
  "CLEAR_INVESTOR_DASHBOARD_PROJECT";
