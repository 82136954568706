export const styles = () => ({
  mainDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "600px", // Set your width here
        minHeight: "220px",
        // boxShadow: "0px 25px 25px 0px #00000040",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
      },
    },
  },

  closeIconRight: {
    marginRight: 10,
    marginTop: 10,
    cursor: "pointer",
    background: "transparent",
    color: "#010101",
    borderRadius: "36px ",
    width: "24px",
    height: "24px",
  },
  dialogContent: {
    alginItems: "center",
    fontWight: "400",
    color: "#4F4F4F",
    width: "80%",
    margin: "auto",
    textAlign: "center",
    "@media (max-width:600px)": {
      width: "95%",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #EEEEEE",
    paddingBottom: "10px",
  },
  modalImg: {
    height: "70px",
    width: "70px",
  },
  navigateLogin: {
    width: "350px !important",
  },
  modalBottomButton: {
    paddingBottom: "20px",
  },
  mainTitle: {
    fontSize: "20px",
    fontWeight: "500",
    padding: "5px",
    // marginTop: "30px",

    "@media (max-width:600px)": {
      fontSize: "16px",
      padding: "5px",
      fontWeight: "500",
    },
    "@media (max-width:375px)": {
      fontSize: "16px",
      padding: "5px",
      fontWeight: "500",
    },
  },
  subTitle: {
    color: "text",
    fontSize: "16px",
    fontWeight: "400",
    textColor: "grey",
    textAlign: "center",
    "@media (max-width:600px)": {
      fontSize: "14px",
    },
  },
  bottomTitle: {
    width: "500px",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "40px",
    textAlign: "center",
    marginBottom: "20px",
    "@media (max-width:600px)": {
      fontSize: "20px",
      fontWeight: "600",
    },
  },
  modalTitle: {
    width: "500px",
    fontWeight: "700",
    fontSize: "30px",
    lineHight: "40px",
    textAlign: "center",
    "@media (max-width:600px)": {
      fontSize: "20px",
      fontWeight: "600",
    },
  },
  mkButton: {
    marginRight: "50px",
    width: "25%",
    color: "#040407",
    "@media (max-width:600px)": {
      width: "100%",
      margin: " 0px 20px",
    },
  },
  mkConfirmationButtonSuccess: {
    width: "30%",
    background: "#F33",
    color: "#FFF",
    "@media (max-width:600px)": {
      // width: "100%",
      margin: " 0px 10px",
    },
  },
  mkConfirmationButtonCancel: {
    width: "30%",
    color: "#010101",
    border: "1px solid #E0E0E0",
    "@media (max-width:600px)": {
      // width: "100%",
      margin: " 0px 10px",
    },
  },
  dialogTitle: {
    padding: "0px",
  },
  modalButton: {
    paddingBottom: "50px",
    alignItems: "center",
  },
  dialod: {
    ariaLabelledby: "responsive-dialog-title",
    maxWidth: "670px",
    paddingBottom: "100px",
  },
  subtitle: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: 400,
    "@media (max-width:600px)": {
      fontSize: "13px",
      fontWeight: "400",
    },
  },
  subtitle1: {
    color: "#303030",
    fontSize: "24px",
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: "13px",
      fontWeight: "400",
    },
  },
  link: {
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "400",
    fontSize: "14px",
    color: "#2D9CDB",
  },
  buttonContainer: {
    paddingBottom: "50px",
    alignItems: "center",
  },
  width220: {
    width: "220px",
  },
  checkedCircleStyle1: { height: 60, width: 60 },
  checkedCircleStyle: { height: 125, width: 125 },
  copyStyle: { height: 24, width: 24 },
  dividerText: {
    color: "#1B1C1E",
    fontSize: "14px",
    marginTop: "-8px",
  },
  copyContainer: {
    width: "200px",
    height: "45px",
    border: "1px solid #EEEEEE",
    borderRadius: "4px",
    background: "#FAFAFA",
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  copyText: {
    color: "#4F4F4F",
    fontSize: "14px",
    marginRight: "10px",
  },
  depositModalTitle: {
    width: 670,
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "35px",
    margin: "20px 0px",
  },
  depositSubititle1: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    margin: "auto",
    color: "#2D355A",
    marginTop: "20px",
    "@media (max-width:600px)": {
      marginTop: "15px",
    },
  },
  depositSelect: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18.75px",
    marginRight: "auto",
  },
  depositSelectBox: {
    borderTop: "1px solid #E5E5E5",
    paddingTop: "25px",
    marginTop: "25px",
    flexDirection: "column",
  },
  depositModalSelect: {
    color: "##1B1C1E",
  },
  depositRadioGroup: {
    marginRight: "auto",
  },
  depositModalRadioInput: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "32vw",
    justifyContent: "space-between",
    border: "0.5px solid #c9c3c3",
    borderWidth: "thin",
    borderRadius: "5px",
    margin: "10px 0px",
    "@media (max-width:800px)": {
      width: "60vw",
    },
    "@media (max-width:700px)": {
      width: "65vw",
    },
    "@media (max-width:425px)": {
      width: "70vw",
    },
  },
  depoitContinueBtn: {
    width: "50%",
    background: "#58F2F0",
    color: "#040407",
    border: "none",
    marginBottom: "40px",
  },
  txContinueBtn: {
    width: "50%",
    background: "#286C45",
    color: "#fff",
    border: "none",
    marginBottom: "24px",
    marginTop: "16px",
  },
  selectedOption: {
    border: "0.5px solid #58F2F0",
    borderWidth: "thin",
    borderRadius: "5px",
  },
  selectBoxMainContainer: {
    margin: "16px 0px 20px",
  },
  selectBoxContainer: {
    height: "48px",
    width: "100%",
    border: "1px solid #EEEEEE",
    borderRadius: "4px",
    marginBottom: "10px",
    cursor: "pointer",
    padding: "0px 15px",
    display: "flex",
    alignItems: "center",
  },
  selectBoxContainerSelected: {
    height: "48px",
    width: "100%",
    borderRadius: "4px",
    marginBottom: "10px",
    cursor: "pointer",
    border: "1px solid #58F2F0",
    padding: "0px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listBox: {
    padding: "16px 12px",
    margin: "0 40px",
    background: "#FFF3F4",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0E111D",
  },
  checkedIcon: {
    height: "24px",
    width: "24px",
  },
  currentBalance: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#4F4F4F",
  },

  //------------ create project select css ------------
  outlinedButton: {
    width: "100%",
    border: "1px solid #E0E0E0",
    padding: "10px",
    borderRadius: "6px",
    color: "black !important",
    fontSize: "20px",
    fontWeight: 700,
    "&:hover": {
      border: "1px solid #E0E0E0",
      color: "black !important",
    },
  },
  containedButton: {
    width: "100%",
    padding: "10px",
    borderRadius: "6px",
    fontSize: "20px",
    fontWeight: 700,
    background: "#F2FAF5",
    color: "black !important",
    border: "1px solid #286C45",
    "&:hover": {
      color: "black !important",
    },
  },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    textAlign: "left",
  },
  buttonSpan: {
    fontSize: "16px",
    fontWeight: 400,
  },
  next: {
    width: "25%",
    background: "#286C45",
    boxShadow: "0px 8px 16px 0px #00000033",
    color: "white !important",
    fontSize: "16px",
    fontWeight: 500,
    "&:hover": {
      background: "#286C45",
      color: "white !important",
    },
  },
  retireBtn: {
    width: "100%",
  },
  //---------------- fcc modal css--------------
  fccDialog: {
    minWidth: "700px !important",
    borderRadius: "16px !important",
  },
  skipFcc: {
    color: "black !important",
    backgroundColor: "white",
    margin: "20px",
    width: "30%",
    fontSize: "16px",
    border: "1px solid #E0E0E0",
  },
  continueFcc: {
    color: "white !important",
    backgroundColor: "#286C45",
    margin: "20px",
    width: "30%",
    fontSize: "16px",
    border: "1px solid #E0E0E0",
    "&:hover": {
      color: "white !important",
      backgroundColor: "#286C45",
    },
  },
  fcc: {
    margin: "10px",
  },
  approvedMint: {
    width: "45%",
    background: "#286C45",
    boxShadow: "0px 8px 16px 0px #00000033",
    color: "white !important",
    fontSize: "16px",
    fontWeight: 500,
    "&:hover": {
      background: "#286C45",
      color: "white !important",
    },
  },
  query: {
    width: "50%",
    background: "#286C45",
    boxShadow: "0px 8px 16px 0px #00000033",
    color: "white !important",
    fontSize: "16px",
    fontWeight: 500,
    "&:hover": {
      background: "#286C45",
      color: "white !important",
    },
  },
  ErrorMessage: {
    color: "red",
    textAlign: "left",
  },
  inputLabel: {
    textAlign: "left !important",
    marginRight: "auto",
    marginTop: "5px !important",
  },
  clickLink: {
    color: "#1A96F0 !important",
  },
  investBoxDialog: {
    padding: "0 70px",
    overflowY: "unset",
  },

  investButton: {
    minWidth: "200px",
    fontSize: "1rem",
    fontWeight: "500",
  },
  milestone: {
    width: "40%",
    background: "#286C45",
    boxShadow: "0px 8px 16px 0px #00000033",
    color: "white !important",
    fontSize: "16px",
    fontWeight: 500,
    "&:hover": {
      background: "#286C45",
      color: "white !important",
    },
  },
  labelColor: {
    color: "#616161",
  },
  TextFieldColor: {
    color: "black !important",
    fontSize: "14px",
    fontWeight: 400,
  },
  updateDialog: {
    maxHeight: "calc(100vh - 150px)",
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
  },
});
