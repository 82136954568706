import * as actionLabels from "../../actionLabels";

export const initialState = {
  project: {},
  projectSummary: {},
  projectSummaryTotals: {},
  errorMsg: "",
  fccDetails: null,
  mintingData: null,
  projectFCC: "",
  listProject: null,
  buyTransactionsData: null,
  sellTransactionsData: null,
  withdrawTransactionsData: null,
  tokenTransactionsData: null,
  kybQuestions: null,
  updateCountsData: null,
  templateData: null,
  isLoading: {
    project: false,
    saving: false,
    submiting: false,
    payment: false,
    stripeSetup: false,
    stripeLink: false,
    description: false,
    descriptionSave: false,
    finalize: false,
    finalizeSave: false,
    monitoring: false,
    monitoringSave: false,
    fetchmonitoring: false,
    submitfcc: false,
    listedproject: false,
    updates: false,
    buy: false,
    sell: false,
    withdraw: false,
    token: false,
    kybSubmit: false,
    kybQuestions: false,
    updateCounts: false,
    template: false,
    reading: false,
    sending: false,
  },

  isResetPasswordLinkValid: false,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // PROJECT
    case actionLabels.PROJECT_FETCH_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          project: true,
        },
      };
    case actionLabels.PROJECT_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          project: false,
        },
        errorMsg: "",
        project: payload,
      };
    }
    case actionLabels.PROJECT_FETCH_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          project: false,
        },
        errorMsg: payload,
      };
    }
    // SAVE PROJECT
    case actionLabels.PROJECT_SAVE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [payload]: true,
        },
      };
    case actionLabels.PROJECT_SAVE_SUCCESS: {
      if (payload?._id) localStorage.setItem("projectId", payload._id);
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          saving: false,
          submiting: false,
        },
        errorMsg: "",
        // project: { ...state.project, isRecent: true },
        projectFCC: payload?._projectFcc,
      };
    }
    case actionLabels.PROJECT_SAVE_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          saving: false,
          submiting: false,
        },
        errorMsg: payload,
      };
    }

    // Project description
    case actionLabels.PROJECT_SAVE_DESC_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [payload]: true,
        },
      };
    }
    case actionLabels.PROJECT_SAVE_DESC_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          description: false,
          descriptionSave: false,
        },
        errorMsg: payload,
      };
    }
    case actionLabels.PROJECT_SAVE_DESC_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          description: false,
          descriptionSave: false,
        },
        errorMsg: "",
      };
    }

    // Project description finalize
    case actionLabels.PROJECT_SAVE_DESC_FINALIZATION_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [payload]: true,
        },
      };
    }
    case actionLabels.PROJECT_SAVE_DESC_FINALIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          finalize: false,
          finalizeSave: false,
        },
      };
    }

    case actionLabels.PROJECT_SAVE_DESC_FINALIZATION_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          finalize: false,
          finalizeSave: false,
        },
      };
    }

    // Monitoring save document

    case actionLabels.MONITORING_SAVE_START: {
      return { ...state, isLoading: { ...state.isLoading, [payload]: true } };
    }
    case actionLabels.MONITORING_SAVE_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          monitoring: false,
          monitoringSave: false,
        },
      };
    }
    case actionLabels.MONITORING_SAVE_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          monitoring: false,
          monitoringSave: false,
        },
      };
    }

    // Monitoring get details

    case actionLabels.MONITORING_FETCH_START: {
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchmonitoring: true },
      };
    }

    case actionLabels.MONITORING_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: { ...state.isLoading, fetchmonitoring: false },
        project: {
          ...state.project,
          monitoringData: payload,
          isRecentMonitor: true,
        },
      };
    }

    case actionLabels.MONITORING_FETCH_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fetchmonitoring: false,
        },
        project: {
          ...state.project,
          monitoringData: {},
          isRecentMonitor: true,
        },
      };
    }

    // FCC submit deatils

    case actionLabels.SUBMIT_PROJECT_FCC_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          submitfcc: true,
        },
      };
    }

    case actionLabels.SUBMIT_PROJECT_FCC_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          submitfcc: false,
        },
        fccDetails: {
          ...state.fccDetails,
          ...payload,
          ...payload?.fcc,
          isSubmitted: true,
        },
      };
    }

    case actionLabels.SUBMIT_PROJECT_FCC_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          submitfcc: false,
        },
      };
    }

    case actionLabels.GET_PROJECT_FCC_SUCCESS: {
      return {
        ...state,
        fccDetails: payload,
      };
    }

    case actionLabels.MINT_FCC_SUCCESS: {
      return {
        ...state,
        fccDetails: { ...state.fccDetails, isMinted: true },
      };
    }

    case actionLabels.PAYMENT_INTENT_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          payment: true,
        },
      };
    case actionLabels.PAYMENT_INTENT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          payment: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.PAYMENT_INTENT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          payment: false,
        },
        errorMsg: payload,
      };
    }
    case actionLabels.SETUP_INTENT_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          stripeSetup: true,
        },
      };
    case actionLabels.SETUP_INTENT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          stripeSetup: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.SETUP_INTENT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          stripeSetup: false,
        },
        errorMsg: payload,
      };
    }
    case actionLabels.FETCH_STRIPE_LINK_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          stripeLink: true,
        },
      };
    case actionLabels.FETCH_STRIPE_LINK_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          stripeLink: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.FETCH_STRIPE_LINK_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          stripeLink: false,
        },
        errorMsg: payload,
      };
    }

    //transactions
    //buy
    case actionLabels.GET_PAYMENT_INTENT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          buy: false,
        },
        buyTransactionsData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_PAYMENT_INTENT_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          buy: true,
        },
      };
    case actionLabels.GET_PAYMENT_INTENT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          buy: false,
        },
        errorMsg: "",
      };
    }

    //sell
    case actionLabels.GET_SELL_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sell: false,
        },
        sellTransactionsData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_SELL_TRANSACTIONS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sell: true,
        },
      };
    case actionLabels.GET_SELL_TRANSACTIONS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sell: false,
        },
        errorMsg: "",
      };
    }

    //withdraw
    case actionLabels.GET_WITHDRAW_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          withdraw: false,
        },
        withdrawTransactionsData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_WITHDRAW_TRANSACTIONS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          withdraw: true,
        },
      };
    case actionLabels.GET_WITHDRAW_TRANSACTIONS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          withdraw: false,
        },
        errorMsg: "",
      };
    }

    //token
    case actionLabels.GET_TOKEN_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          token: false,
        },
        tokenTransactionsData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_TOKEN_TRANSACTIONS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          token: true,
        },
      };
    case actionLabels.GET_TOKEN_TRANSACTIONS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          token: false,
        },
        errorMsg: "",
      };
    }

    case actionLabels.GET_LIST_PROJECT_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedproject: true,
        },
      };
    }
    case actionLabels.GET_LIST_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedproject: false,
        },
        listProject: payload,
      };
    }
    case actionLabels.GET_LIST_PROJECT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedproject: false,
        },
      };
    }

    case actionLabels.GET_MINTED_FCC_SUCCESS: {
      return {
        ...state,
        mintingData: payload,
      };
    }
    case actionLabels.GET_CONVERSATIONS_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updates: true,
        },
      };
    }
    case actionLabels.GET_CONVERSATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updates: false,
        },
        updates: payload,
      };
    }
    case actionLabels.GET_CONVERSATIONS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updates: false,
        },
      };
    }
    case actionLabels.GET_CONVERSATIONS_DETAILS_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updates: true,
        },
      };
    }
    case actionLabels.GET_CONVERSATIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updates: false,
        },
        updatesDetails: payload,
      };
    }
    case actionLabels.GET_CONVERSATIONS_DETAILS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updates: false,
        },
      };
    }
    case actionLabels.SEND_MESSAGE_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sending: true,
        },
      };
    }
    case actionLabels.SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sending: false,
        },
      };
    }
    case actionLabels.SEND_MESSAGE_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sending: false,
        },
      };
    }
    case actionLabels.READ_MESSAGE_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reading: true,
        },
      };
    }
    case actionLabels.READ_MESSAGE_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reading: false,
        },
      };
    }
    case actionLabels.READ_MESSAGE_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          reading: false,
        },
      };
    }

    case actionLabels.CLEAR_PROJECT: {
      return initialState;
    }
    case actionLabels.GET_LISTING_HISTORY_FCC_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedFcc: true,
        },
      };
    case actionLabels.GET_LISTING_HISTORY_FCC_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedFcc: false,
        },
        listedFcc: payload,
      };
    case actionLabels.GET_LISTING_HISTORY_FCC_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedFcc: false,
        },
      };
    case actionLabels.GET_LISTED_VCC_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedVCC: true,
        },
      };
    case actionLabels.GET_LISTED_VCC_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedVCC: false,
        },
        listedVCC: payload,
      };
    case actionLabels.GET_LISTED_VCC_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedVCC: false,
        },
      };
    case actionLabels.GET_PROJECT_SUMMARY_TOTALS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectSummaryTotals: false,
        },
      };
    case actionLabels.GET_PROJECT_SUMMARY_TOTALS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectSummaryTotals: false,
        },
        projectSummaryTotals: payload,
      };
    case actionLabels.GET_PROJECT_SUMMARY_TOTALS_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectSummaryTotals: false,
        },
      };
    case actionLabels.GET_PROJECT_SUMMARY_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectSummary: false,
        },
      };
    case actionLabels.GET_PROJECT_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectSummary: false,
        },
        projectSummary: payload,
      };
    case actionLabels.GET_PROJECT_SUMMARY_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectSummary: false,
        },
      };
    case actionLabels.GET_LISTING_HISTORY_VCC_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          vssHistory: false,
        },
      };
    case actionLabels.GET_LISTING_HISTORY_VCC_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          vssHistory: false,
        },
        vssHistory: payload,
      };
    case actionLabels.GET_LISTING_HISTORY_VCC_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          vssHistory: false,
        },
      };

    case actionLabels.GET_RETIRE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          retire: false,
        },
      };
    case actionLabels.GET_RETIRE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          retire: false,
        },
        retire: payload,
      };
    case actionLabels.GET_RETIRE_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          retire: false,
        },
      };
    case actionLabels.GET_TOKEN_BUY_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          tokenBuy: false,
        },
      };
    case actionLabels.GET_TOKEN_BUY_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          tokenBuy: false,
        },
        tokenBuy: payload,
      };
    case actionLabels.GET_TOKEN_BUY_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          tokenOwner: false,
        },
      };

    case actionLabels.GET_TOKEN_OWNER_DETAIL_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          tokenOwner: false,
        },
      };
    case actionLabels.GET_TOKEN_OWNER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          tokenOwner: false,
        },
        tokenOwner: payload,
      };
    case actionLabels.GET_TOKEN_OWNER_DETAIL_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          tokenOwner: false,
        },
      };
    case actionLabels.UPDATE_ACCOUNT_STATUS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          accountStatus: false,
        },
      };
    case actionLabels.UPDATE_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          accountStatus: false,
        },
        accountStatus: payload,
      };
    case actionLabels.UPDATE_ACCOUNT_STATUS_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          accountStatus: false,
        },
      };
    case actionLabels.GET_FIAT_TRANSACTION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fiatTransaction: false,
        },
      };
    case actionLabels.GET_FIAT_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fiatTransaction: false,
        },
        fiatTransaction: payload,
      };
    case actionLabels.GET_FIAT_TRANSACTION_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          fiatTransaction: false,
        },
      };
    case actionLabels.GET_KYB_QUESTIONS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kybQuestions: false,
        },
      };
    case actionLabels.GET_KYB_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kybQuestions: false,
        },
        kybQuestions: payload,
      };
    case actionLabels.GET_KYB_QUESTIONS_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kybQuestions: false,
        },
      };
    case actionLabels.SUBMIT_KYB_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kybSubmit: true,
        },
      };
    case actionLabels.SUBMIT_KYB_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kybSubmit: false,
        },
      };
    case actionLabels.SUBMIT_KYB_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kybSubmit: false,
        },
      };

    //get update counts
    case actionLabels.GET_UPDATE_COUNTS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateCounts: true,
        },
      };
    case actionLabels.GET_UPDATE_COUNTS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateCounts: false,
        },
        updateCountsData: payload,
      };
    case actionLabels.GET_UPDATE_COUNTS_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateCounts: false,
        },
      };

    //get template
    case actionLabels.GET_TEMPLATE_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          template: true,
        },
      };
    case actionLabels.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          template: false,
        },
        templateData: payload,
      };
    case actionLabels.GET_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          template: false,
        },
      };

    default:
      return state;
  }
};
