export default {
  styleOverrides: {
    active: {
      line: {
        borderColor: "#E5E5E5",
        borderTopStyle: "dotted",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "#286C45",
        borderColor: "#286C45",
      },
    },
    alternativeLabel: {
        left: `calc(-50% + 40px)`,
      right: `calc(50% + 40px)`,
      },
    line: {
      // borderTopWidth: "2px",
      // borderTopStyle: "dashed",
      // borderColor: "#E5E5E5",
      // position: "absolute",
      // top: "12px",
      // left: `calc(-50% + 40px)`,
      // right: `calc(50% + 40px)`,
    },
  },
};
