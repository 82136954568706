import * as actionLabels from "../../actionLabels";

export const initialState = {
  countryList: null,
  statesList: null,
  methodologiesList: null,
  standardProgramsList: null,
  standardsList: null,
  sectorsList: null,
  vintageYearsList: null,
  projectVintageYearsList: null,
  listedVintageYearsList: null,
  errorMsg: "",
  isLoading: {
    country: false,
    states: false,
    methodologies: false,
    standardPrograms: false,
    standards: false,
    sectors: false,
    vintageYears: false,
    projectVintageYears: false,
    listedVintageYears: false,
  },

  isResetPasswordLinkValid: false,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // countries
    case actionLabels.GET_COUNTRIES_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          country: true,
        },
      };
    case actionLabels.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          country: false,
        },
        countryList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_COUNTRIES_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          country: false,
        },
        errorMsg: payload,
      };
    }
    // states
    case actionLabels.GET_STATES_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          states: true,
        },
      };
    case actionLabels.GET_STATES_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          states: false,
        },
        statesList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_STATES_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          states: false,
        },
        errorMsg: payload,
      };
    }
    // methodologies
    case actionLabels.GET_METHODOLOGIES_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          methodologies: true,
        },
      };
    case actionLabels.GET_METHODOLOGIES_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          methodologies: false,
        },
        methodologiesList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_METHODOLOGIES_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          methodologies: false,
        },
        errorMsg: payload,
      };
    }
    // standard programs
    case actionLabels.GET_STANDARD_PROGRAMS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          standardPrograms: true,
        },
      };
    case actionLabels.GET_STANDARD_PROGRAMS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          standardPrograms: false,
        },
        standardProgramsList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_STANDARD_PROGRAMS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          standardPrograms: false,
        },
        errorMsg: payload,
      };
    }
    // standard
    case actionLabels.GET_STANDARDS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          standards: true,
        },
      };
    case actionLabels.GET_STANDARDS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          standards: false,
        },
        standardsList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_STANDARDS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          standards: false,
        },
        errorMsg: payload,
      };
    }
    //  sectors
    case actionLabels.GET_SECTORS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sectors: true,
        },
      };
    case actionLabels.GET_SECTORS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sectors: false,
        },
        sectorsList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_SECTORS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          sectors: false,
        },
        errorMsg: payload,
      };
    }
    //  vintage years
    case actionLabels.GET_VINTAGE_YEARS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          vintageYears: true,
        },
      };
    case actionLabels.GET_VINTAGE_YEARS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          vintageYears: false,
        },
        vintageYearsList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_VINTAGE_YEARS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          vintageYears: false,
        },
        errorMsg: payload,
      };
    }

    // project vintage years
    case actionLabels.GET_PROJECT_VINTAGE_YEARS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectVintageYears: true,
        },
      };
    case actionLabels.GET_PROJECT_VINTAGE_YEARS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectVintageYears: false,
        },
        projectVintageYearsList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_PROJECT_VINTAGE_YEARS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          projectVintageYears: false,
        },
        errorMsg: payload,
      };
    }

    // listed vintage years
    case actionLabels.GET_LISTED_VINTAGE_YEARS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedVintageYears: true,
        },
      };
    case actionLabels.GET_LISTED_VINTAGE_YEARS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedVintageYears: false,
        },
        listedVintageYearsList: payload,
        errorMsg: "",
      };
    }
    case actionLabels.GET_LISTED_VINTAGE_YEARS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          listedVintageYears: false,
        },
        errorMsg: payload,
      };
    }

    default:
      return state;
  }
};
