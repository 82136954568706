export function parseUrl() {
  // Get the current URL
  var currentURL = window.location.href;

  // Check if there is a "?" in the URL
  var index = currentURL.indexOf("?");
  if (index !== -1) {
    // Remove everything after "?"
    var updatedURL = currentURL.substring(0, index);

    // Log the updated URL to the console

    // Return the updated URL
    return updatedURL;
  }

  // Return the original URL if there are no query parameters
  return currentURL;
}

export function numberWithCommas(x) {
  x = x?.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
}

export function truncateDecimals(number, digits) {
  const stringNumber = number?.toString(); // Convert to string
  const decimalIndex = stringNumber?.indexOf(".");
  if (decimalIndex !== -1) {
    // If a decimal point exists
    let truncatedString = stringNumber?.slice(0, decimalIndex + digits + 1); // Include two decimal places
    // Add trailing zeros if necessary
    if (truncatedString?.split(".")[1].length < digits) {
      truncatedString += "0"?.repeat(
        digits - truncatedString?.split(".")[1].length
      );
    }
    return truncatedString;
  } else {
    // If no decimal point exists, append ".00"
    return stringNumber + "." + "0"?.repeat(digits);
  }
}
