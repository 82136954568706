import React from "react";
// import {
//   Login,
//   // Register,
//   EmailVerificationSuccess,
//   ResetPassword,
//   PersonalInformation,
//   PageNotFound,
//   // QrCodeModal,
//   Wallet,
//   Accounts,
//   Investor,
// } from "views";
// import TwoFactorAuthentication from "views/auth/Login/TwoFactorAuthentication";
// // import RegisterSelect from "views/auth/Register/RegisterSelect";
// import InvestorDetails from "views/auth/Register/steps/InvestorDetails";
// import DemoModal from "views/DemoModal";
// import UpdatesLayout from "components/Updates";
// import ProjectSummary from "views/dashboard/projectSummary";
// import Stripe from "components/StripeCard";
// import Notification from "views/KYC/Notification";

// Routes Management
export const guestRoutes = [
  {
    path: "/register",
    exact: true,
    name: "Layout",
    component: React.lazy(() => import("views/auth/Register/RegisterSelect")),
  },

  {
    path: "/",
    exact: true,
    name: "Login",
    component: React.lazy(() => import("views/auth/Login/Login")),
  },
  {
    path: "/otp",
    exact: true,
    name: "otp",
    component: React.lazy(() =>
      import("views/auth/Login/TwoFactorAuthentication")
    ),
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "Forgot password",
    component: React.lazy(() => import("views/auth/Login/ResetPassword")),
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset password",
    component: React.lazy(() => import("views/auth/Login/CreatNewPassword")),
  },
  {
    path: "/email-verification/:id",
    exact: true,
    name: "EmailVerificationSuccess",
    component: React.lazy(() =>
      import("views/auth/Register/EmailVerificationSuccess")
    ),
  },
  // {
  //   path: "/register/personal-information",
  //   exact: true,
  //   name: "PersonalDetails",
  //   component: React.lazy(() => <></>),
  // },
  {
    path: "/register/details",
    exact: true,
    name: "Details",
    component: React.lazy(() =>
      import("views/auth/Register/steps/InvestorDetails")
    ),
  },
  {
    path: "/terms",
    exact: true,
    name: "Terms and Conditions",
    // component: <Notification />,
    component: React.lazy(() => import("views/terms")),
  },
  {
    path: "/privacy",
    exact: true,
    name: "Privacy Policy",
    // component: <Notification />,
    component: React.lazy(() => import("views/privacy")),
  },

  // {
  //   path: "/page-not-found",
  //   exact: true,
  //   name: "PageNotFound",
  //   // component: <PageNotFound />,
  //   component: React.lazy(() => <></>),
  // },

  {
    path: "/demo-modal",
    exact: true,
    name: "DemoModal",
    // component: <DemoModal />,
    component: React.lazy(() => <></>),
  },

  {
    redirectRoute: true,
    redirectPath: "/",
    path: "*",
    name: "PageNotFound",
  },

  // Protected routes start there which will further moved to seperate component
];

export const customRoutes = [
  {
    path: "/verification-stepper",
    exact: true,
    name: "Stepper",
    component: React.lazy(() =>
      import("views/auth/Register/steps/stepperInvestor")
    ),
  },
  {
    path: "/profile",
    exact: true,
    name: "Profle",
    component: React.lazy(() => import("views/KYC/KYCProfile")),
  },
  {
    path: "/document-upload",
    exact: true,
    name: "Document Upload",
    // component: <FileSubmit />,
    component: React.lazy(() => import("views/KYC/FileSubmit")),
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    // component: <Notification />,
    component: React.lazy(() => import("views/KYC/Notification")),
  },
  {
    path: "/stripe/:secret",
    exact: true,
    name: "Stripe",
    // component: <Stripe />,
    component: React.lazy(() => import("components/StripeCard")),
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    // component: <Notification />,
    component: React.lazy(() => import("views/KYC/Notification")),
  },
  {
    path: "/terms",
    exact: true,
    name: "Terms and Conditions",
    // component: <Notification />,
    component: React.lazy(() => import("views/terms")),
  },
  {
    path: "/privacy",
    exact: true,
    name: "Privacy Policy",
    // component: <Notification />,
    component: React.lazy(() => import("views/privacy")),
  },
  {
    path: "/page-not-found",
    exact: true,
    name: "PageNotFound",
    // component: <PageNotFound />,
    component: React.lazy(() => import("views/page-not-found/index")),
  },
  {
    redirectRoute: true,
    redirectPath: "/verification-stepper",
    path: "*",
    name: "PageNotFound",
  },
];

export const userRoutes = [
  {
    path: "/verification-stepper",
    exact: true,
    name: "Stepper",
    component: React.lazy(() =>
      import("views/auth/Register/steps/stepperInvestor")
    ),
  },
  // {
  //   path: "/wallet",
  //   exact: true,
  //   name: "Wallet",
  //   // component: <Wallet />,
  //   component: React.lazy(() => <></>),
  // },
  // {
  //   path: "/accounts",
  //   exact: true,
  //   name: "Accounts",
  //   // component: <Accounts />,
  //   component: React.lazy(() => <></>),
  // },
  {
    path: "/investor",
    exact: true,
    name: "Investor",
    // component: <Investor />,
    component: React.lazy(() => import("views/investor")),
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: React.lazy(() => import("views/KYC/KYCPage")),
  },
  {
    path: "/dashboard-listing",
    exact: true,
    name: "Dashboard-listing",
    // component: <Dashboard />,
    component: React.lazy(() => import("views/dashboard/Dashboard")),
  },
  {
    path: "/investor-dashboard-listing",
    exact: true,
    name: "Dashboard-listing",
    // component: <InvestorDashboard />,
    component: React.lazy(() => import("views/dashboard/InvestorDashboard")),
  },
  {
    path: "/transactions",
    exact: true,
    name: "Transactions",
    component: React.lazy(() => import("views/transaction/Transaction")),
  },
  {
    path: "/marketplace",
    exact: true,
    name: "project-listing",
    component: React.lazy(() => import("views/marketplace")),
  },
  {
    path: "/list-project/:id",
    exact: true,
    name: "project-listing",
    component: React.lazy(() => import("views/list-project/ListProject")),
  },
  {
    path: "/project-profile/:id",
    exact: true,
    name: "project-listing",
    // component: <ProjectProfile />,
    component: React.lazy(() => import("views/list-project/ProjectProfile")),
  },
  // {
  //   path: "/document-upload",
  //   exact: true,
  //   name: "Document Upload",
  //   // component: <FileSubmit />,
  //   component: React.lazy(() => import("views/KYC/FileSubmit")),
  // },
  {
    path: "/profile",
    exact: true,
    name: "Profle",
    component: React.lazy(() => import("views/KYC/KYCProfile")),
  },
  {
    path: "/layout/:id",
    exact: true,
    name: "Create project",
    component: React.lazy(() => import("components/createProjectLayout")),
    access: "developer",
  },
  {
    path: "/updates/:id",
    exact: true,
    name: "Updates",
    component: React.lazy(() => import("components/Updates")),
    access: "developer",
  },
  {
    path: "/projectSummary/:id",
    exact: true,
    name: "Project Summary",
    component: React.lazy(() => import("views/dashboard/projectSummary")),
  },
  {
    path: "/stripe/:secret",
    exact: true,
    name: "Stripe",
    // component: <Stripe />,
    component: React.lazy(() => import("components/StripeCard")),
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    // component: <Notification />,
    component: React.lazy(() => import("views/KYC/Notification")),
  },
  {
    path: "/terms",
    exact: true,
    name: "Terms and Conditions",
    // component: <Notification />,
    component: React.lazy(() => import("views/terms")),
  },
  {
    path: "/privacy",
    exact: true,
    name: "Privacy Policy",
    // component: <Notification />,
    component: React.lazy(() => import("views/privacy")),
  },
  {
    //temprory added
    redirectRoute: true,
    redirectPath: "/marketplace",
    path: "*",
    name: "Marketplace-redirect",
  },
];
