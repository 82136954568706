// @mui material components
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default styled(Typography)(({ theme, ownerState }) => {
  const { palette, typography, functions } = theme;

  const {
    color,
    textTransform,
    verticalAlign,
    fontWeight,
    opacity,
    textGradient,
    fontSize,
  } = ownerState;

  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fontValue = fontSize?.split("px");
  const customFontSize =
    mediumScreen && fontSize && !smallScreen
      ? `${fontValue[0] - 2}px`
      : smallScreen && fontSize
      ? `${fontValue[0] - 4}px`
      : fontSize;

  const { gradients, transparent } = palette;
  const {
    fontWeightLighter,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    fontWeightExtraBold,
  } = typography;
  const { linearGradient } = functions;

  // fontWeight styles
  const fontWeights = {
    extralight: fontWeightLighter, //100
    light: fontWeightLight, //300
    regular: fontWeightRegular, //400
    medium: fontWeightMedium, //500
    bold: fontWeightBold, //700
    extrabold: fontWeightExtraBold, //900
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color !== "inherit" &&
      color !== "text" &&
      color !== "white" &&
      gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.dark.main, gradients.dark.state),
    display: "inline-block",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: transparent.main,
    position: "relative",
    zIndex: 1,
  });

  return {
    opacity,
    textTransform,
    verticalAlign,
    fontSize: customFontSize,
    textDecoration: "none",
    color,
    letterSpacing: "-0.125px",
    fontWeight: fontWeights[fontWeight] && fontWeights[fontWeight],
    ...(textGradient && gradientStyles()),
  };
});
