import { SET_ACTIVE_STEP } from "store/actionLabels";

const initialState = {
  activeStep: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    default:
      return state;
  }
};
