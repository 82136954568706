import * as actionLabels from "../../actionLabels";

export const initialState = {
  futureProjectList: [],
  liveProjectList: [],
  publicConsultationProjectList: [],
  futureProjectAllData: null,
  liveProjectAllData: null,
  publicConsultationProjectAllData: null,
  errorMsg: "",
  isLoading: {
    futureProjectLoad: false,
    liveProjectLoad: false,
    publicConsultationProjectLoad: false,
    getProjects: false,
    getInvestorProjects: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    //future projects
    case actionLabels.GET_FUTURE_PROJECTS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          futureProjectLoad: true,
        },
      };
    case actionLabels.GET_FUTURE_PROJECTS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          futureProjectLoad: false,
        },
        futureProjectList: payload?.data,
        futureProjectAllData: payload?.response,
        errorMsg: "",
      };
    }
    case actionLabels.GET_FUTURE_PROJECTS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          futureProjectLoad: false,
        },
        errorMsg: payload,
      };
    }

    //live projects
    case actionLabels.GET_LIVE_PROJECTS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          liveProjectLoad: true,
        },
      };
    case actionLabels.GET_LIVE_PROJECTS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          liveProjectLoad: false,
        },
        liveProjectList: payload?.data,
        liveProjectAllData: payload?.response,
        errorMsg: "",
      };
    }
    case actionLabels.GET_LIVE_PROJECTS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          liveProjectLoad: false,
        },
        errorMsg: payload,
      };
    }

    //public consultation projects
    case actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          publicConsultationProjectLoad: true,
        },
      };
    case actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          publicConsultationProjectLoad: false,
        },
        publicConsultationProjectList: payload?.data,
        publicConsultationProjectAllData: payload?.response,
        errorMsg: "",
      };
    }
    case actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          publicConsultationProjectLoad: false,
        },
        errorMsg: payload,
      };
    }

    // Get dashboar project
    case actionLabels.GET_DASHBOARD_PROJECT_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getProjects: true,
        },
      };
    }
    case actionLabels.GET_DASHBOARD_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getProjects: false,
        },
        [`${payload.name}Project`]: { ...payload.data, fetched: true },
      };
    }
    case actionLabels.GET_DASHBOARD_PROJECT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getProjects: false,
        },
      };
    }

    case actionLabels.CLEAR_DASHBOARD_PROJECT: {
      return {
        ...state,
        draftProject: null,
        publishProject: null,
        offerProject: null,
        seedProject: null,
      };
    }

    // investor dashboard
    // Get dashboar project
    case actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_START: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInvestorProjects: true,
        },
      };
    }
    case actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInvestorProjects: false,
        },
        [`${payload.name}Project`]: { ...payload.data, fetched: true },
      };
    }
    case actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInvestorProjects: false,
        },
      };
    }

    case actionLabels.CLEAR_INVESTOR_DASHBOARD_PROJECT: {
      return {
        ...state,
        fccHoldingsProject: null,
        vccHoldingsProject: null,
        listingProject: null,
        retirementsProject: null,
        submittedProject: null,
        recievedProject: null,
      };
    }

    default:
      return state;
  }
};
