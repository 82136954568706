import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { guestRoutes, userRoutes, customRoutes } from "routes";
import { useDispatch, useSelector } from "react-redux";
import StatusModal from "components/Modal/StatusModal";
import AuthLayout from "components/AuthLayout";
import Layout from "components/Layout/Layout";
import CircularProgress from "@mui/material/CircularProgress";

import {
  authenticationValidator,
  profileFetch,
  getFiatTransaction,
} from "store/actions";
// import FullPageLoader from "components/Loader/FullPageLoader";

import "react-toastify/dist/ReactToastify.css";
import "draft-js/dist/Draft.css";
import "./styles/index.css";

const App = () => {
  // Setting up routes for every path defined in guestRoutes

  const token = localStorage.getItem("authToken");
  const authToken = useSelector((state) => state.auth?.authToken);
  const fiatTransactionPayload = useSelector(
    (state) => state.project?.fiatTransaction
  );
  var searchParams = new URLSearchParams(window.location.search);
  var fiatTransaction = searchParams.get("fiatTransaction");
  const userData = useSelector((state) => state?.auth?.userData);

  const [statusPopup, setStatusPopup] = React.useState(false);
  const [profileFetchStatus, setProfileFetchStatus] = React.useState("");

  function removeQueryParam(key) {
    // Create a URLSearchParams object from the current URL

    // Remove the specified query parameter
    searchParams.delete(key);

    // Create a new URL with the updated query parameters
    var newUrl =
      window.location.origin +
      window.location.pathname +
      "?" +
      searchParams.toString();

    // Replace the current URL without triggering a page refresh
    window.history.replaceState({ path: newUrl }, "", newUrl);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (fiatTransaction) {
      dispatch(getFiatTransaction({ _fiatTransaction: fiatTransaction }));
    }
  }, [fiatTransaction]);

  useEffect(() => {
    if (token) {
      dispatch(authenticationValidator({ token }));
      dispatch(
        profileFetch({
          onStart: () => setProfileFetchStatus(""),
          onSuccess: () => setProfileFetchStatus("success"),
          onFail: () => setProfileFetchStatus("fail"),
        })
      );
    }
    setTimeout(() => {
      removeQueryParam("connect");
      removeQueryParam("refresh");
      removeQueryParam("status");
      removeQueryParam("redirect_status");
    }, 1000);
  }, []);

  useEffect(() => {
    fiatTransactionPayload?.status == "succeeded" && setStatusPopup(true);
    removeQueryParam("fiatTransaction");
  }, [fiatTransactionPayload]);
  let mainContent = (
    <AuthLayout>
      <Routes>
        {guestRoutes.map((route) =>
          !route.redirectRoute ? (
            <Route
              key={route.name}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<route.component />}
            />
          ) : (
            route.redirectRoute &&
            (profileFetchStatus === "success" || !token) && (
              <Route
                path={route.path}
                key={route.name}
                element={<Navigate to={route.redirectPath} />}
              />
            )
          )
        )}
      </Routes>
    </AuthLayout>
  );

  const canAccessDashboard =
    userData?.userType === "developer" && userData?.registrationFee?.isPaid;

  const canAccessInvestorDashboard =
    userData?.userType === "investor" &&
    // !userData?.registrationFee?.isPaid &&
    userData?.stripe?.account?.status === "verified";

  const isAuthorized = canAccessDashboard || canAccessInvestorDashboard;

  if ((authToken || token) && !isAuthorized) {
    mainContent = (
      <Layout>
        <Routes>
          {customRoutes.map((route) =>
            !route.redirectRoute ? (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.component />}
              />
            ) : (
              route.redirectRoute &&
              profileFetchStatus === "success" && (
                <Route
                  path={route.path}
                  key={route.name}
                  element={<Navigate to={route.redirectPath} />}
                />
              )
            )
          )}
        </Routes>
      </Layout>
    );
  }

  if ((authToken || token) && isAuthorized) {
    mainContent = (
      <Layout>
        <Routes>
          {userRoutes.map((route) =>
            !route.redirectRoute ? (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.component />}
              />
            ) : (
              route.redirectRoute &&
              profileFetchStatus === "success" && (
                <Route
                  path={route.path}
                  key={route.name}
                  element={<Navigate to={route.redirectPath} />}
                />
              )
            )
          )}
        </Routes>
      </Layout>
    );
  }

  return (
    <Suspense
      fallback={
        <CircularProgress
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            bottom: "50%",
            right: "50%",
            color: "#286C45",
          }}
        />
      }
    >
      {/* <Suspense fallback={<>Loading...</>}> */}
      <BrowserRouter>
        {/* <Header /> */}
        {/* <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={<route.component />}
            />
          ))}
        </Routes> */}
        {mainContent}
        {/* <Footer /> */}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
          progressClassName="toastProgress"
          bodyClassName="toastBody"
        />
        {/* <FullPageLoader open={isLoading.profile} /> */}
      </BrowserRouter>
      <StatusModal
        open={statusPopup}
        title={
          // fiatTransactionPayload?.status == "succeeded"
          fiatTransactionPayload?.fiatStatus == "succeeded"
            ? "Transaction Successful"
            : "Transaction Failed"
        }
        status={fiatTransactionPayload?.status || "succeeded"}
        handleClose={() => setStatusPopup((prev) => !prev)}
      />
    </Suspense>
  );
};

export default App;
