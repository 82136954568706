import * as actionLabels from "../../actionLabels";

// future projects

export const getFutureProjectsStart = () => ({
  type: actionLabels.GET_FUTURE_PROJECTS_START,
});

export const getFutureProjects = (payload) => ({
  type: actionLabels.GET_FUTURE_PROJECTS_SAGA,
  payload,
});

export const getFutureProjectsSuccess = (payload) => ({
  type: actionLabels.GET_FUTURE_PROJECTS_SUCCESS,
  payload,
});

export const getFutureProjectsFail = (payload) => ({
  type: actionLabels.GET_FUTURE_PROJECTS_FAIL,
  payload,
});

// live projects

export const getLiveProjectsStart = () => ({
  type: actionLabels.GET_LIVE_PROJECTS_START,
});

export const getLiveProjects = (payload) => ({
  type: actionLabels.GET_LIVE_PROJECTS_SAGA,
  payload,
});

export const getLiveProjectsSuccess = (payload) => ({
  type: actionLabels.GET_LIVE_PROJECTS_SUCCESS,
  payload,
});

export const getLiveProjectsFail = (payload) => ({
  type: actionLabels.GET_LIVE_PROJECTS_FAIL,
  payload,
});

// Projects for Public Consultation

export const getPublicConsultationProjectsStart = () => ({
  type: actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_START,
});

export const getPublicConsultationProjects = (payload) => ({
  type: actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_SAGA,
  payload,
});

export const getPublicConsultationProjectsSuccess = (payload) => ({
  type: actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_SUCCESS,
  payload,
});

export const getPublicConsultationProjectsFail = (payload) => ({
  type: actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_FAIL,
  payload,
});

export const getDashboardProject = (payload) => ({
  type: actionLabels.GET_DASHBOARD_PROJECT_SAGA,
  payload,
});

export const getDashboardProjectStart = (payload) => ({
  type: actionLabels.GET_DASHBOARD_PROJECT_START,
  payload,
});

export const getDashboardProjectSuccess = (payload) => ({
  type: actionLabels.GET_DASHBOARD_PROJECT_SUCCESS,
  payload,
});

export const getDashboardProjectFail = (payload) => ({
  type: actionLabels.GET_DASHBOARD_PROJECT_FAIL,
  payload,
});

export const clearDashboardData = () => ({
  type: actionLabels.CLEAR_DASHBOARD_PROJECT,
});

// investor dashboard
export const getInvestorDashboardProject = (payload) => ({
  type: actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_SAGA,
  payload,
});

export const getInvestorDashboardProjectStart = (payload) => ({
  type: actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_START,
  payload,
});

export const getInvestorDashboardProjectSuccess = (payload) => ({
  type: actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_SUCCESS,
  payload,
});

export const getInvestorDashboardProjectFail = (payload) => ({
  type: actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_FAIL,
  payload,
});

export const clearInvestorDashboardData = () => ({
  type: actionLabels.CLEAR_INVESTOR_DASHBOARD_PROJECT,
});
