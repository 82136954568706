import * as actionLabels from "../../actionLabels";

const OTHERS = { name: "Others", state_code: "OTH" };
export const initialState = {
  isLogin: false,
  isLoggedOut: false,
  isSignup: false,
  isMobileOTPSent: false,
  isMobileOTPVerified: false,
  userData: null,
  getUserProfileData: null,
  signupData: "karan@yopmail.com",
  authToken: "",
  errorMsg: "",
  isLoading: {
    login: false,
    loginVerify: false,
    logout: false,
    profile: false,
    auth: false,
    signup: false,
    resendEmailVerification: false,
    OTPVerify: false,
    OTPSend: false,
    forgetPassword: false,
    resetPassword: false,
    resendOtp: false,
    emailVerification: false,
    checkResetPasswordLinkValidity: false,
    getLocation: false,
  },
  location: {
    country: [],
    state: [],
    city: [],
  },
  email: "",
  isResetPasswordLinkValid: false,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Login
    case actionLabels.LOGIN_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          login: true,
        },
      };
    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          login: false,
        },
        userData: payload,
        errorMsg: "",
        isLogin: true,
      };
    }
    case actionLabels.LOGIN_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          login: false,
        },
        errorMsg: payload,
      };
    }

    // Login Verify
    case actionLabels.LOGIN_VERIFY_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          loginVerify: true,
        },
      };
    case actionLabels.LOGIN_VERIFY_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          loginVerify: false,
        },
        userData: payload,
        errorMsg: "",
        isLogin: true,
      };
    }
    case actionLabels.LOGIN_VERIFY_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          loginVerify: false,
        },
        errorMsg: payload,
      };
    }

    // Profile
    case actionLabels.PROFILE_FETCH_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          profile: true,
        },
      };
    case actionLabels.PROFILE_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          profile: false,
        },
        userData: payload,
        getUserProfileData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.PROFILE_FETCH_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          profile: false,
        },
        errorMsg: payload,
      };
    }

    // Logout
    case actionLabels.LOGOUT_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logout: true,
        },
      };
    case actionLabels.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logout: false,
        },
        userData: payload,
        errorMsg: "",
        isLoggedOut: true,
      };
    }
    case actionLabels.LOGOUT_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          logout: false,
        },
      };
    }

    // Login Authentication

    case actionLabels.AUTH_LOGIN_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          auth: true,
        },
      };
    case actionLabels.AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        isLogin: true,
        isLoading: {
          ...state.isLoading,
          auth: false,
        },
        userData: payload,
        errorMsg: "",
        authToken: payload,
      };
    }
    case actionLabels.AUTH_LOGIN_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          auth: false,
        },
        errorMsg: payload,
      };
    }

    // Signup reducers

    case actionLabels.SIGNUP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          signup: true,
        },
      };
    case actionLabels.SIGNUP_SUCCESS: {
      return {
        ...state,
        isSignup: true,
        isLoading: {
          ...state.isLoading,
          signup: false,
        },
        signupData: payload,
        errorMsg: "",
      };
    }
    case actionLabels.SIGNUP_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          signup: false,
        },
        errorMsg: payload,
      };
    }

    // Resend email verification reducers

    case actionLabels.RESEND_EMAIL_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resendEmailVerification: true,
        },
      };
    case actionLabels.RESEND_EMAIL_SUCCESS: {
      return {
        ...state,
        isSignup: true,
        isLoading: {
          ...state.isLoading,
          resendEmailVerification: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.RESEND_EMAIL_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resendEmailVerification: false,
        },
        errorMsg: payload,
      };
    }

    // Mobile send otp

    case actionLabels.PHONE_SEND_OTP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          OTPSend: true,
        },
      };
    case actionLabels.PHONE_SEND_OTP_SUCCESS: {
      return {
        ...state,
        isMobileOTPSent: true,
        isLoading: {
          ...state.isLoading,
          OTPSend: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.PHONE_SEND_OTP_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          OTPSend: false,
        },
        errorMsg: payload,
      };
    }

    // Mobile verify otp

    case actionLabels.PHONE_VERIFY_OTP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          OTPVerify: true,
        },
      };
    case actionLabels.PHONE_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        isMobileOTPVerified: true,
        isLoading: {
          ...state.isLoading,
          OTPVerify: false,
        },
        errorMsg: "",
        signupData: payload,
      };
    }
    case actionLabels.PHONE_VERIFY_OTP_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          OTPVerify: false,
        },
        errorMsg: payload,
      };
    }

    // Forgot Password

    case actionLabels.FORGOT_PASSWORD_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          forgetPassword: true,
        },
      };
    case actionLabels.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          forgetPassword: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          forgetPassword: false,
        },
        errorMsg: payload,
      };
    }

    // Email verification

    case actionLabels.EMAIL_VERIFICATION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          emailVerification: true,
        },
      };
    case actionLabels.EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          emailVerification: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.EMAIL_VERIFICATION_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          emailVerification: false,
        },
        errorMsg: payload,
      };
    }

    // Reset Password

    case actionLabels.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resetPassword: true,
        },
      };
    case actionLabels.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resetPassword: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resetPassword: false,
        },
        errorMsg: payload,
      };
    }

    // Resend Otp

    case actionLabels.RESEND_OTP_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resendOtp: true,
        },
      };
    case actionLabels.RESEND_OTP_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resendOtp: false,
        },
        errorMsg: "",
      };
    }
    case actionLabels.RESEND_OTP_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          resendOtp: false,
        },
        errorMsg: payload,
      };
    }

    // Reset Password when logged in

    case actionLabels.RESET_PASSWORD_AUTH_START:
      return { ...state, isLoading: true };
    case actionLabels.RESET_PASSWORD_AUTH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: "",
      };
    }
    case actionLabels.CHECK_USER_ERROR: {
      return {
        ...state,
        [payload.name]: payload.error,
      };
    }
    case actionLabels.RESET_PASSWORD_AUTH_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.CHECK_RESET_PASSWORD_LINK_VALIDITY_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          checkResetPasswordLinkValidity: true,
        },
      };
    case actionLabels.CHECK_RESET_PASSWORD_LINK_VALIDITY_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          checkResetPasswordLinkValidity: false,
        },
        isResetPasswordLinkValid: payload,
        errorMsg: "",
      };
    }
    case actionLabels.CHECK_RESET_PASSWORD_LINK_VALIDITY_FAIL: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          checkResetPasswordLinkValidity: false,
        },
        isResetPasswordLinkValid: false,
        errorMsg: payload,
      };
    }
    case actionLabels.GET_LOCATION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getLocation: true,
        },
      };
    case actionLabels.GET_LOCATION_SUCCESS: {
      const { locationType, data } = payload;
      let list = [];

      if (locationType === "state") {
        list = data.data.states;
      } else {
        list = data;
      }
      const places = locationType === "state" ? [...list, OTHERS] : list;

      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getLocation: false,
        },
        location: {
          ...state.location,
          [locationType]: places,
        },

        errorMsg: "",
      };
    }
    case actionLabels.GET_LOCATION_FAIL: {
      const { locationType, msg } = payload;

      const noState = locationType === "state" ? [OTHERS] : [];
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getLocation: false,
        },
        location: {
          ...state.location,
          [locationType]: noState,
        },
        errorMsg: msg,
      };
    }
    case actionLabels.AUTHENTICATION_VALIDATOR: {
      return { ...state, authToken: payload?.token };
    }
    default:
      return state;
  }
};
