import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "./auth/auth";
import modal from "./modal/modal";
import stepper from "./stepper/stepper";
import project from "./project/project";
import dropdown from "./dropdown/dropdown";
import marketplace from "./marketplace/marketplace";

const persistConfig = {
  key: "root",
  storage,
  // List of all reducers we don't want to persist
  blacklist: ["auth", "user", "property"],
  // List of all reducers we want to persist
  whitelist: [],
};

// Persist Only User -> User profile reducer
// const userProfilePersistConfig = {
//   key: "user",
//   storage,
//   whitelist: ["userProfile"],
// };

const allReducers = combineReducers({
  auth,
  modal,
  stepper,
  project,
  dropdown,
  marketplace,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return persistedReducer(state, action);
};

export default rootReducer;
