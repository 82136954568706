import { all, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionLabels from "../actionLabels";

// Auth actions based imports
import {
  loginSaga,
  logoutSaga,
  profileFetchSaga,
  profileUpdateSaga,
  loginAuthenticationSaga,
  authenticationValidatorSaga,
  signupSaga,
  sendOTPSaga,
  emailVerificationSaga,
  verifyOTPSaga,
  forgotPasswordSaga,
  resetPasswordSaga,
  resetPasswordAuthSaga,
  resendOTPSaga,
  resendEmailVerificationSaga,
  userCheckSaga,
  checkResetPasswordLinkValidity,
  getLocations,
} from "./auth/auth";

import {
  projectFetchSaga,
  projectSaveSaga,
  paymentIntentSage,
  setupIntentSage,
  fetchStripeAccountLink,
  registerInenttSaga,
  getPaymentIntent,
  getSellIntent,
  getWithdrawIntent,
  getTokenIntent,
  projectDescriptionSaga,
  projectFinalizeSaga,
  projectMonitoringSaga,
  fetchMonitoringSaga,
  submitProjectFCCSaga,
  getProjectFCCSaga,
  getListedProjectSaga,
  getMintedFCCSaga,
  getListedUpdateSaga,
  getUpdateDetailsSaga,
  sendMessage,
  readMessage,
  projectListSubmitSaga,
  getProjectSummarySaga,
  getProjectSummaryTotalsSaga,
  getListingHistoryFCCSaga,
  getListedVCCSaga,
  getTokenRetireTransactionsSaga,
  getTokenBuyTransactionsSaga,
  getTokenOwnerDetailSaga,
  updateAccountStatusSaga,
  getFiatTransactionSaga,
  getKYBQuestions,
  submitKYB,
  getUpdateCountsSaga,
  getTemplateSaga,
} from "./project/project";

import {
  getCountriesSaga,
  getStatesSaga,
  getMethodologiesSaga,
  getStandardProgramsSaga,
  getStandardsSaga,
  getSectorsSaga,
  getVintageYearsSaga,
  getProjectVintageYearsSaga,
  getListedVintageYearsSaga,
} from "./dropdown/dropdown";

import {
  getFutureProjectsSaga,
  getLiveProjectsSaga,
  getPublicConsultationProjectsSaga,
  getDashboardProjectsSaga,
  getInvestorDashboardProjectsSaga,
} from "./marketplace/marketplace";

// Auth actions based saga
export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.LOGOUT_SAGA, logoutSaga),
    takeLatest(actionLabels.AUTH_LOGIN_SAGA, loginAuthenticationSaga),
    takeLatest(actionLabels.PROFILE_FETCH_SAGA, profileFetchSaga),
    takeLatest(actionLabels.PROFILE_UPDATE_SAGA, profileUpdateSaga),
    takeLatest(actionLabels.SIGNUP_SAGA, signupSaga),
    takeLatest(actionLabels.PHONE_SEND_OTP_SAGA, sendOTPSaga),
    takeLatest(actionLabels.EMAIL_VERIFICATION_SAGA, emailVerificationSaga),
    takeLatest(actionLabels.PHONE_VERIFY_OTP_SAGA, verifyOTPSaga),
    takeLatest(actionLabels.FORGOT_PASSWORD_SAGA, forgotPasswordSaga),
    takeLatest(actionLabels.RESET_PASSWORD_SAGA, resetPasswordSaga),
    takeLatest(actionLabels.RESEND_OTP_SAGA, resendOTPSaga),
    takeLatest(actionLabels.RESET_PASSWORD_AUTH_SAGA, resetPasswordAuthSaga),
    takeLatest(actionLabels.RESEND_EMAIL_SAGA, resendEmailVerificationSaga),
    takeLatest(actionLabels.PROJECT_FETCH_SAGA, projectFetchSaga),
    takeLatest(actionLabels.PROJECT_SAVE_SAGA, projectSaveSaga),
    takeLatest(actionLabels.PAYMENT_INTENT_SAGA, paymentIntentSage),
    takeLatest(actionLabels.SETUP_INTENT_SAGA, setupIntentSage),
    takeLatest(actionLabels.FETCH_STRIPE_LINK_SAGA, fetchStripeAccountLink),
    takeLatest(actionLabels.REGISTER_INTENT_SAGA, registerInenttSaga),
    takeLatest(actionLabels.GET_PAYMENT_INTENT_SAGA, getPaymentIntent),
    takeLatest(actionLabels.GET_SELL_TRANSACTIONS_SAGA, getSellIntent),
    takeLatest(actionLabels.GET_WITHDRAW_TRANSACTIONS_SAGA, getWithdrawIntent),
    takeLatest(actionLabels.GET_TOKEN_TRANSACTIONS_SAGA, getTokenIntent),
    takeLatest(actionLabels.GET_KYB_QUESTIONS_SAGA, getKYBQuestions),
    takeLatest(actionLabels.SUBMIT_KYB_SAGA, submitKYB),

    takeLatest(actionLabels.PROJECT_SAVE_DESC_SAGA, projectDescriptionSaga),
    takeLatest(
      actionLabels.PROJECT_SAVE_DESC_FINALIZATION_SAGA,
      projectFinalizeSaga
    ),
    takeLatest(actionLabels.GET_PROJECT_SUMMARY_SAGA, getProjectSummarySaga),
    takeLatest(actionLabels.MONITORING_FETCH_SAGA, fetchMonitoringSaga),
    takeLatest(actionLabels.MONITORING_SAVE_SAGA, projectMonitoringSaga),
    takeLatest(actionLabels.SUBMIT_PROJECT_FCC_SAGA, submitProjectFCCSaga),
    takeLatest(actionLabels.GET_PROJECT_FCC_SAGA, getProjectFCCSaga),
    takeLatest(actionLabels.GET_LIST_PROJECT_SAGA, getListedProjectSaga),
    takeLatest(actionLabels.PROJECT_LIST_SUBMIT_SAGA, projectListSubmitSaga),
    takeLatest(actionLabels.GET_MINTED_FCC_SAGA, getMintedFCCSaga),
    takeLatest(actionLabels.GET_CONVERSATIONS_SAGA, getListedUpdateSaga),
    takeLatest(actionLabels.SEND_MESSAGE__SAGA, sendMessage),
    takeLatest(actionLabels.READ_MESSAGE__SAGA, readMessage),
    takeLatest(actionLabels.GET_UPDATE_COUNTS_SAGA, getUpdateCountsSaga),
    takeLatest(actionLabels.GET_TEMPLATE_SAGA, getTemplateSaga),
    takeLatest(
      actionLabels.GET_CONVERSATIONS_DETAILS_SAGA,
      getUpdateDetailsSaga
    ),
    takeLatest(
      actionLabels.AUTHENTICATION_VALIDATOR,
      authenticationValidatorSaga
    ),
    takeLatest(actionLabels.CHECK_USER, userCheckSaga),

    takeLatest(
      actionLabels.CHECK_RESET_PASSWORD_LINK_VALIDITY_SAGA,
      checkResetPasswordLinkValidity
    ),
    takeLatest(
      actionLabels.GET_PROJECT_SUMMARY_TOTALS_SAGA,
      getProjectSummaryTotalsSaga
    ),
    takeLatest(actionLabels.GET_LISTED_VCC_SAGA, getListedVCCSaga),
    takeLatest(
      actionLabels.GET_LISTING_HISTORY_FCC_SAGA,
      getListingHistoryFCCSaga
    ),
    takeLatest(actionLabels.GET_TOKEN_BUY_SAGA, getTokenBuyTransactionsSaga),
    takeLatest(actionLabels.GET_RETIRE_SAGA, getTokenRetireTransactionsSaga),
    takeLatest(
      actionLabels.GET_TOKEN_OWNER_DETAIL_SAGA,
      getTokenOwnerDetailSaga
    ),
    takeLatest(
      actionLabels.UPDATE_ACCOUNT_STATUS_SAGA,
      updateAccountStatusSaga
    ),

    takeEvery(actionLabels.GET_LOCATION_SAGA, getLocations),
    takeEvery(actionLabels.GET_COUNTRIES_SAGA, getCountriesSaga),
    takeEvery(actionLabels.GET_STATES_SAGA, getStatesSaga),
    takeEvery(actionLabels.GET_METHODOLOGIES_SAGA, getMethodologiesSaga),
    takeEvery(actionLabels.GET_STANDARD_PROGRAMS_SAGA, getStandardProgramsSaga),
    takeEvery(actionLabels.GET_STANDARDS_SAGA, getStandardsSaga),
    takeEvery(actionLabels.GET_SECTORS_SAGA, getSectorsSaga),
    takeEvery(actionLabels.GET_VINTAGE_YEARS_SAGA, getVintageYearsSaga),
    takeEvery(
      actionLabels.GET_PROJECT_VINTAGE_YEARS_SAGA,
      getProjectVintageYearsSaga
    ),
    takeEvery(
      actionLabels.GET_LISTED_VINTAGE_YEARS_SAGA,
      getListedVintageYearsSaga
    ),
    takeEvery(actionLabels.GET_FUTURE_PROJECTS_SAGA, getFutureProjectsSaga),
    takeEvery(actionLabels.GET_LIVE_PROJECTS_SAGA, getLiveProjectsSaga),
    takeEvery(actionLabels.GET_FIAT_TRANSACTION_SAGA, getFiatTransactionSaga),

    takeEvery(
      actionLabels.GET_PUBLIC_CONSULTATION_PROJECTS_SAGA,
      getPublicConsultationProjectsSaga
    ),
    takeEvery(
      actionLabels.GET_DASHBOARD_PROJECT_SAGA,
      getDashboardProjectsSaga
    ),
    takeEvery(
      actionLabels.GET_INVESTOR_DASHBOARD_PROJECT_SAGA,
      getInvestorDashboardProjectsSaga
    ),
  ]);
}
