import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
// import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

// Static imports

import { HeaderLogo } from "constants/assets";
import styles from "./styles";
import MKBox from "components/custom/MKBox";

const pages = [
  {
    name: "News",
    link: "https://www.infrablockstechnologies.com/news",
    newTab: true,
  },
  {
    name: "Support",
    link: "https://infrablockshelp.zendesk.com/hc/en-gb",
    newTab: true,
  },
  {
    name: "Terms & Conditions",
    link: "/terms",
    newTab: true,
  },
  {
    name: "Privacy Policy",
    link: "/privacy",
    newTab: true,
  },
];

const Footer = ({ classes }) => {
  const active = true;
  const currentYear = new Date().getFullYear(); // Get current year

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container className={classes.headerContainer}>
        <Toolbar disableGutters className={classes.height100}>
          <MKBox className={classes.logobox}>
            <img src={HeaderLogo} className={classes.logo} alt="Logo" />
            <Typography
              variant="h6"
              noWrap
              display="inline"
              className={`${classes.headerTitle} ${classes.displayLG}`}
            >
              Copywrite ©{currentYear}
            </Typography>
          </MKBox>
          <Box className={classes.actionboxcontainer}>
            {active && (
              <Box className={classes.actionsBox}>
                {pages.map((page) => (
                  <Box key={page.name} className={classes.dFlex}>
                    <a
                      href={page.link}
                      target={page.newTab ? "_blank" : "_self"}
                      rel="noreferrer"
                      className={classes.navLinkText}
                    >
                      {page.name}
                    </a>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box className={classes.socials}>
            <Box className={classes.icons}>
              {/* <a
                href="#"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#fff" }}
              >
                <FacebookIcon />
              </a> */}
              <a
                href="https://twitter.com/Infrablockstech"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#fff" }}
              >
                <TwitterIcon />
              </a>
              <a
                // href="https://www.instagram.com/infrablocks/"
                href="https://www.instagram.com/infrablockstechnologies/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#fff" }}
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/31490791/admin/feed/posts/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#fff" }}
              >
                <LinkedInIcon />
              </a>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default withStyles(styles)(Footer);
