// countries

export const GET_COUNTRIES_SAGA = "GET_COUNTRIES_SAGA";
export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

// states

export const GET_STATES_SAGA = "GET_STATES_SAGA";
export const GET_STATES_START = "GET_STATES_START";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAIL = "GET_STATES_FAIL";

// methodologies

export const GET_METHODOLOGIES_SAGA = "GET_METHODOLOGIES_SAGA";
export const GET_METHODOLOGIES_START = "GET_METHODOLOGIES_START";
export const GET_METHODOLOGIES_SUCCESS = "GET_METHODOLOGIES_SUCCESS";
export const GET_METHODOLOGIES_FAIL = "GET_METHODOLOGIES_FAIL";

// standard programs

export const GET_STANDARD_PROGRAMS_SAGA = "GET_STANDARD_PROGRAMS_SAGA";
export const GET_STANDARD_PROGRAMS_START = "GET_STANDARD_PROGRAMS_START";
export const GET_STANDARD_PROGRAMS_SUCCESS = "GET_STANDARD_PROGRAMS_SUCCESS";
export const GET_STANDARD_PROGRAMS_FAIL = "GET_STANDARD_PROGRAMS_FAIL";

// standards

export const GET_STANDARDS_SAGA = "GET_STANDARDS_SAGA";
export const GET_STANDARDS_START = "GET_STANDARDS_START";
export const GET_STANDARDS_SUCCESS = "GET_STANDARDS_SUCCESS";
export const GET_STANDARDS_FAIL = "GET_STANDARDS_FAIL";

// sectors

export const GET_SECTORS_SAGA = "GET_SECTORS_SAGA";
export const GET_SECTORS_START = "GET_SECTORS_START";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_FAIL = "GET_SECTORS_FAIL";

// vintage years

export const GET_VINTAGE_YEARS_SAGA = "GET_VINTAGE_YEARS_SAGA";
export const GET_VINTAGE_YEARS_START = "GET_VINTAGE_YEARS_START";
export const GET_VINTAGE_YEARS_SUCCESS = "GET_VINTAGE_YEARS_SUCCESS";
export const GET_VINTAGE_YEARS_FAIL = "GET_VINTAGE_YEARS_FAIL";

// project vintage years

export const GET_PROJECT_VINTAGE_YEARS_SAGA = "GET_PROJECT_VINTAGE_YEARS_SAGA";
export const GET_PROJECT_VINTAGE_YEARS_START =
  "GET_PROJECT_VINTAGE_YEARS_START";
export const GET_PROJECT_VINTAGE_YEARS_SUCCESS =
  "GET_PROJECT_VINTAGE_YEARS_SUCCESS";
export const GET_PROJECT_VINTAGE_YEARS_FAIL = "GET_PROJECT_VINTAGE_YEARS_FAIL";

// listed vintage years

export const GET_LISTED_VINTAGE_YEARS_SAGA = "GET_LISTED_VINTAGE_YEARS_SAGA";
export const GET_LISTED_VINTAGE_YEARS_START = "GET_LISTED_VINTAGE_YEARS_START";
export const GET_LISTED_VINTAGE_YEARS_SUCCESS =
  "GET_LISTED_VINTAGE_YEARS_SUCCESS";
export const GET_LISTED_VINTAGE_YEARS_FAIL = "GET_LISTED_VINTAGE_YEARS_FAIL";
