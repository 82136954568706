// Project
export const PROJECT_FETCH_SAGA = "PROJECT_FETCH_SAGA";
export const PROJECT_FETCH_START = "PROJECT_FETCH_START";
export const PROJECT_FETCH_SUCCESS = "PROJECT_FETCH_SUCCESS";
export const PROJECT_FETCH_FAIL = "PROJECT_FETCH_FAIL";

// Save      Details
export const PROJECT_SAVE_SAGA = "PROJECT_SAVE_SAGA";
export const PROJECT_SAVE_START = "PROJECT_SAVE_START";
export const PROJECT_SAVE_SUCCESS = "PROJECT_SAVE_SUCCESS";
export const PROJECT_SAVE_FAIL = "PROJECT_SAVE_FAIL";

// project list submit
export const PROJECT_LIST_SUBMIT_SAGA = "PROJECT_LIST_SUBMIT_SAGA";
export const PROJECT_LIST_SUBMIT_START = "PROJECT_LIST_SUBMIT_START";
export const PROJECT_LIST_SUBMIT_SUCCESS = "PROJECT_LIST_SUBMIT_SUCCESS";
export const PROJECT_LIST_SUBMIT_FAIL = "PROJECT_LIST_SUBMIT_FAIL";

// Save Project Description
export const PROJECT_SAVE_DESC_SAGA = "PROJECT_SAVE_DESC_SAGA";
export const PROJECT_SAVE_DESC_START = "PROJECT_SAVE_DESC_START";
export const PROJECT_SAVE_DESC_SUCCESS = "PROJECT_SAVE_DESC_SUCCESS";
export const PROJECT_SAVE_DESC_FAIL = "PROJECT_SAVE_DESC_FAIL";

//Submit  Project Description
export const PROJECT_SUBMIT_DESC_SAGA = "PROJECT_SUBMIT_DESC_SAGA";
export const PROJECT_SUBMIT_DESC_START = "PROJECT_SUBMIT_DESC_START";
export const PROJECT_SUBMIT_DESC_SUCCESS = "PROJECT_SUBMIT_DESC_SUCCESS";
export const PROJECT_SUBMIT_DESC_FAIL = "PROJECT_SUBMIT_DESC_FAIL";

// Save Project Description Finalization
export const PROJECT_SAVE_DESC_FINALIZATION_SAGA =
  "PROJECT_SAVE_DESC_FINALIZATION_SAGA";
export const PROJECT_SAVE_DESC_FINALIZATION_START =
  "PROJECT_SAVE_DESC_FINALIZATION_START";
export const PROJECT_SAVE_DESC_FINALIZATION_SUCCESS =
  "PROJECT_SAVE_DESC_FINALIZATION_SUCCESS";
export const PROJECT_SAVE_DESC_FINALIZATION_FAIL =
  "PROJECT_SAVE_DESC_FINALIZATION_FAIL";

//Submit  Project Description
export const PROJECT_SUBMT_DESC_FINALIZATION_SAGA =
  "PROJECT_SUBMT_DESC_FINALIZATION_SAGA";
export const PROJECT_SUBMT_DESC_FINALIZATION_START =
  "PROJECT_SUBMT_DESC_FINALIZATION_START";
export const PROJECT_SUBMT_DESC_FINALIZATION_SUCCESS =
  "PROJECT_SUBMT_DESC_FINALIZATION_SUCCESS";
export const PROJECT_SUBMT_DESC_FINALIZATION_FAIL =
  "PROJECT_SUBMT_DESC_FINALIZATION_FAIL";

// Fetch Monitoring
export const MONITORING_FETCH_SAGA = "MONITORING_FETCH_SAGA";
export const MONITORING_FETCH_START = "MONITORING_FETCH_START";
export const MONITORING_FETCH_SUCCESS = "MONITORING_FETCH_SUCCESS";
export const MONITORING_FETCH_FAIL = "MONITORING_FETCH_FAIL";

// Save Monitoring
export const MONITORING_SAVE_SAGA = "MONITORING_SAVE_SAGA";
export const MONITORING_SAVE_START = "MONITORING_SAVE_START";
export const MONITORING_SAVE_SUCCESS = "MONITORING_SAVE_SUCCESS";
export const MONITORING_SAVE_FAIL = "MONITORING_SAVE_FAIL";

// Submit Project FCC
export const SUBMIT_PROJECT_FCC_SAGA = "SUBMIT_PROJECT_FCC_SAGA";
export const SUBMIT_PROJECT_FCC_START = "SUBMIT_PROJECT_FCC_START";
export const SUBMIT_PROJECT_FCC_SUCCESS = "SUBMIT_PROJECT_FCC_SUCCESS";
export const SUBMIT_PROJECT_FCC_FAIL = "SUBMIT_PROJECT_FCC_FAIL";

// Get Project FCC
export const GET_PROJECT_FCC_SAGA = "GET_PROJECT_FCC_SAGA";
export const GET_PROJECT_FCC_START = "GET_PROJECT_FCC_START";
export const GET_PROJECT_FCC_SUCCESS = "GET_PROJECT_FCC_SUCCESS";
export const GET_PROJECT_FCC_FAIL = "GET_PROJECT_FCC_FAIL";

// Mint FCC
export const MINT_FCC_SUCCESS = "MINT_FCC_SUCCESS";

// Create Payment Intent
export const PAYMENT_INTENT_SAGA = "PAYMENT_INTENT_SAGA";
export const PAYMENT_INTENT_START = "PAYMENT_INTENT_START";
export const PAYMENT_INTENT_SUCCESS = "PAYMENT_INTENT_SUCCESS";
export const PAYMENT_INTENT_FAIL = "PAYMENT_INTENT_FAIL";

// Create Setup Intent
export const SETUP_INTENT_SAGA = "SETUP_INTENT_SAGA";
export const SETUP_INTENT_START = "SETUP_INTENT_START";
export const SETUP_INTENT_SUCCESS = "SETUP_INTENT_SUCCESS";
export const SETUP_INTENT_FAIL = "SETUP_INTENT_FAIL";

// GET STRIPE ACCOUNT LINK
export const FETCH_STRIPE_LINK_SAGA = "FETCH_STRIPE_LINK_SAGA";
export const FETCH_STRIPE_LINK_START = "FETCH_STRIPE_LINK_START";
export const FETCH_STRIPE_LINK_SUCCESS = "FETCH_STRIPE_LINK_SUCCESS";
export const FETCH_STRIPE_LINK_FAIL = "FETCH_STRIPE_LINK_FAIL";

//Register Intent Sage
export const REGISTER_INTENT_SAGA = "REGISTER_INTENT_SAGA";

// GET STRIPE ACCOUNT LINK
export const GET_PAYMENT_INTENT_SAGA = "GET_PAYMENT_INTENT_SAGA";
export const GET_PAYMENT_INTENT_START = "GET_PAYMENT_INTENT_START";
export const GET_PAYMENT_INTENT_SUCCESS = "GET_PAYMENT_INTENT_SUCCESS";
export const GET_PAYMENT_INTENT_FAIL = "GET_PAYMENT_INTENT_FAIL";

export const GET_SELL_TRANSACTIONS_SAGA = "GET_SELL_TRANSACTIONS_SAGA";
export const GET_SELL_TRANSACTIONS_START = "GET_SELL_TRANSACTIONS_START";
export const GET_SELL_TRANSACTIONS_SUCCESS = "GET_SELL_TRANSACTIONS_SUCCESS";
export const GET_SELL_TRANSACTIONS_FAIL = "GET_SELL_TRANSACTIONS_FAIL";

export const GET_WITHDRAW_TRANSACTIONS_SAGA = "GET_WITHDRAW_TRANSACTIONS_SAGA";
export const GET_WITHDRAW_TRANSACTIONS_START =
  "GET_WITHDRAW_TRANSACTIONS_START";
export const GET_WITHDRAW_TRANSACTIONS_SUCCESS =
  "GET_WITHDRAW_TRANSACTIONS_SUCCESS";
export const GET_WITHDRAW_TRANSACTIONS_FAIL = "GET_WITHDRAW_TRANSACTIONS_FAIL";

export const GET_TOKEN_TRANSACTIONS_SAGA = "GET_TOKEN_TRANSACTIONS_SAGA";
export const GET_TOKEN_TRANSACTIONS_START = "GET_TOKEN_TRANSACTIONS_START";
export const GET_TOKEN_TRANSACTIONS_SUCCESS = "GET_TOKEN_TRANSACTIONS_SUCCESS";
export const GET_TOKEN_TRANSACTIONS_FAIL = "GET_TOKEN_TRANSACTIONS_FAIL";

// Get list project details
export const GET_LIST_PROJECT_SAGA = "GET_LIST_PROJECT_SAGA";
export const GET_LIST_PROJECT_START = "GET_LIST_PROJECT_START";
export const GET_LIST_PROJECT_SUCCESS = "GET_LIST_PROJECT_SUCCESS";
export const GET_LIST_PROJECT_FAIL = "GET_LIST_PROJECT_FAIL";

// Get Minted FCC
export const GET_MINTED_FCC_SAGA = "GET_MINTED_FCC_SAGA";
export const GET_MINTED_FCC_SUCCESS = "GET_MINTED_FCC_SUCCESS";
export const GET_MINTED_FCC_START = "GET_MINTED_FCC_START";
export const GET_MINTED_FCC_FAIL = "GET_MINTED_FCC_FAIL";
// Project Updates
export const GET_CONVERSATIONS_SAGA = "GET_CONVERSATIONS_SAGA";
export const GET_CONVERSATIONS_START = "GET_CONVERSATIONS_START";
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
export const GET_CONVERSATIONS_FAIL = "GET_CONVERSATIONS_FAIL";

// Project Updates Details
export const GET_CONVERSATIONS_DETAILS_SAGA = "GET_CONVERSATIONS_DETAILS_SAGA";
export const GET_CONVERSATIONS_DETAILS_START =
  "GET_CONVERSATIONS_DETAILS_START";
export const GET_CONVERSATIONS_DETAILS_SUCCESS =
  "GET_CONVERSATIONS_DETAILS_SUCCESS";
export const GET_CONVERSATIONS_DETAILS_FAIL = "GET_CONVERSATIONS_DETAILS_FAIL";

// Project Updates Send Message
export const SEND_MESSAGE__SAGA = "SEND_MESSAGE__SAGA";
export const SEND_MESSAGE_START = "SEND_MESSAGE_START";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";

// Project Updates Send Message
export const READ_MESSAGE__SAGA = "READ_MESSAGE__SAGA";
export const READ_MESSAGE_START = "READ_MESSAGE_START";
export const READ_MESSAGE_SUCCESS = "READ_MESSAGE_SUCCESS";
export const READ_MESSAGE_FAIL = "READ_MESSAGE_FAIL";

// CLear project
export const CLEAR_PROJECT = "CLEAR_PROJECT";

//Submit  Project Description
export const GET_PROJECT_SUMMARY_SAGA = "GET_PROJECT_SUMMARY_SAGA";
export const GET_PROJECT_SUMMARY_START = "GET_PROJECT_SUMMARY_START";
export const GET_PROJECT_SUMMARY_SUCCESS = "GET_PROJECT_SUMMARY_SUCCESS";
export const GET_PROJECT_SUMMARY_FAIL = "GET_PROJECT_SUMMARY_FAIL";

// Get Project Summary Totals
export const GET_PROJECT_SUMMARY_TOTALS_SAGA =
  "GET_PROJECT_SUMMARY_TOTALS_SAGA";
export const GET_PROJECT_SUMMARY_TOTALS_START =
  "GET_PROJECT_SUMMARY_TOTALS_START";
export const GET_PROJECT_SUMMARY_TOTALS_SUCCESS =
  "GET_PROJECT_SUMMARY_TOTALS_SUCCESS";
export const GET_PROJECT_SUMMARY_TOTALS_FAIL =
  "GET_PROJECT_SUMMARY_TOTALS_FAIL";

// Get Listing History FCC
export const GET_LISTING_HISTORY_FCC_SAGA = "GET_LISTING_HISTORY_FCC_SAGA";
export const GET_LISTING_HISTORY_FCC_START = "GET_LISTING_HISTORY_FCC_START";
export const GET_LISTING_HISTORY_FCC_SUCCESS =
  "GET_LISTING_HISTORY_FCC_SUCCESS";
export const GET_LISTING_HISTORY_FCC_FAIL = "GET_LISTING_HISTORY_FCC_FAIL";

// Get Listed VCC
export const GET_LISTED_VCC_SAGA = "GET_LISTED_VCC_SAGA";
export const GET_LISTED_VCC_START = "GET_LISTED_VCC_START";
export const GET_LISTED_VCC_SUCCESS = "GET_LISTED_VCC_SUCCESS";
export const GET_LISTED_VCC_FAIL = "GET_LISTED_VCC_FAIL";

// Get Listed History VCC
export const GET_LISTING_HISTORY_VCC_SAGA = "GET_LISTING_HISTORY_VCC_SAGA";
export const GET_LISTING_HISTORY_VCC_START = "GET_LISTING_HISTORY_VCC_START";
export const GET_LISTING_HISTORY_VCC_SUCCESS =
  "GET_LISTING_HISTORY_VCC_SUCCESS";
export const GET_LISTING_HISTORY_VCC_FAIL = "GET_LISTING_HISTORY_VCC_FAIL";

// Get Retire
export const GET_RETIRE_SAGA = "GET_RETIRE_SAGA";
export const GET_RETIRE_START = "GET_RETIRE_START";
export const GET_RETIRE_SUCCESS = "GET_RETIRE_SUCCESS";
export const GET_RETIRE_FAIL = "GET_RETIRE_FAIL";

// Get Buy
export const GET_TOKEN_BUY_SAGA = "GET_RETIRE_SAGA";
export const GET_TOKEN_BUY_START = "GET_TOKEN_BUY_START";
export const GET_TOKEN_BUY_SUCCESS = "GET_TOKEN_BUY_SUCCESS";
export const GET_TOKEN_BUY_FAIL = "GET_TOKEN_BUY_FAIL";

//Get token owner details
export const GET_TOKEN_OWNER_DETAIL_SAGA = "GET_TOKEN_OWNER_DETAIL_SAGA";
export const GET_TOKEN_OWNER_DETAIL_START = "GET_TOKEN_OWNER_DETAIL_START";
export const GET_TOKEN_OWNER_DETAIL_SUCCESS = "GET_TOKEN_OWNER_DETAIL_SUCCESS";
export const GET_TOKEN_OWNER_DETAIL_FAIL = "GET_TOKEN_OWNER_DETAIL_FAIL";

//update account status
export const UPDATE_ACCOUNT_STATUS_SAGA = "UPDATE_ACCOUNT_STATUS_SAGA";
export const UPDATE_ACCOUNT_STATUS_START = "UPDATE_ACCOUNT_STATUS_START";
export const UPDATE_ACCOUNT_STATUS_SUCCESS = "UPDATE_ACCOUNT_STATUS_SUCCESS";
export const UPDATE_ACCOUNT_STATUS_FAIL = "UPDATE_ACCOUNT_STATUS_FAIL";

//get fial transations
export const GET_FIAT_TRANSACTION_SAGA = "GET_FIAT_TRANSACTION_SAGA";
export const GET_FIAT_TRANSACTION_START = "GET_FIAT_TRANSACTION_START";
export const GET_FIAT_TRANSACTION_SUCCESS = "GET_FIAT_TRANSACTION_SUCCESS";
export const GET_FIAT_TRANSACTION_FAIL = "GET_FIAT_TRANSACTION_FAIL";

//get kyb status
export const GET_KYB_QUESTIONS_SAGA = "GET_KYB_QUESTIONS_SAGA";
export const GET_KYB_QUESTIONS_START = "GET_KYB_QUESTIONS_START";
export const GET_KYB_QUESTIONS_SUCCESS = "GET_KYB_QUESTIONS_SUCCESS";
export const GET_KYB_QUESTIONS_FAIL = "GET_KYB_QUESTIONS_FAIL";

//submit kyb
export const SUBMIT_KYB_SAGA = "SUBMIT_KYB_SAGA";
export const SUBMIT_KYB_START = "SUBMIT_KYB_START";
export const SUBMIT_KYB_SUCCESS = "SUBMIT_KYB_SUCCESS";
export const SUBMIT_KYB_FAIL = "SUBMIT_KYB_FAIL";

//get update counts
export const GET_UPDATE_COUNTS_SAGA = "GET_UPDATE_COUNTS_SAGA";
export const GET_UPDATE_COUNTS_START = "GET_UPDATE_COUNTS_START";
export const GET_UPDATE_COUNTS_SUCCESS = "GET_UPDATE_COUNTS_SUCCESS";
export const GET_UPDATE_COUNTS_FAIL = "GET_UPDATE_COUNTS_FAIL";

//get template
export const GET_TEMPLATE_SAGA = "GET_TEMPLATE_SAGA";
export const GET_TEMPLATE_START = "GET_TEMPLATE_START";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAIL = "GET_TEMPLATE_FAIL";
