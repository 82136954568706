import io from "socket.io-client";
import { USER_SUBDOMIN } from "./env";

const token = localStorage.getItem("authToken");
console.log(token, "socket token");

const socket = io(`${USER_SUBDOMIN}/`, {
  // const socket = io(
  //   `https://856f-2401-4900-1f3c-48ed-85de-3cce-458-1c7c.ngrok-free.app`,
  //   {
  // transports: ["websocket", "polling", "flashsocket"],
  path: "/connect-socket",
  extraHeaders: {
    authorization: token,
    // "ngrok-skip-browser-warning": "6024",
  },
  rejectUnauthorized: false,
});

const SOCKET_EVENTS = {
  JOIN: "join", //emit
  MARK_AS_READ: "mark_as_read", //emit
  MARK_ALL_AS_READ: "mark_all_as_read", //emit
  NOTIFICATIONS_LIST: "notification_list", // not req / cancel
  NOTIFICATION: "notification", //on
  CONNECTION: "connection",
  DELETE: "delete",
  DISCONNECT: "disconnect",
};

function connectSocket() {
  socket.on("connect", () => {
    console.log("Socket connected successfully.");
  });
}

function disconnectSocket() {
  socket.disconnect();
}

function on(event, callback) {
  socket.on(event, callback);
}

function off(event, callback) {
  socket.off(event, callback);
}

export { connectSocket, disconnectSocket, on, off, SOCKET_EVENTS, socket };
