const footerStyle = (theme) => ({
  appBar: {
    position: "relative", // Change position to relative
    zIndex: 1000,
    background: "#0D1F0B",
    // height: "55px",
    backgroundSize: "cover",
    padding: "50px 150px",
    [theme.breakpoints.down("sm")]: {
      // height: "80px",
      padding: "30px 35px",
    },
  },
  headerContainer: {
    // Overriding the default padding of mui container
    margin: "0px",
    height: "100%",
    width: "100%",
    // Overriding the default maxWidth of mui container
    maxWidth: "none !important",
    [theme.breakpoints.down("sm")]: {
      // Overriding the default padding of mui container
    },
  },
  logobox: {
    flex: 1,
  },
  height100: {
    height: "100%",
    minHeight: "55px",
    justifyContent: "space-between",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      // flexDirection: "column",
      // justifyContent: "center",
    },
  },
  headerTitle: {
    fontWeight: 400,
    color: "#FEFEFE",
    fontSize: "14px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      fontSize: "12px",
    },
  },
  headerTitle1: {
    fontWeight: 400,
    color: "#2D9CDB",
    fontSize: "14px",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  navLinksBox: {
    flexGrow: 1.5,
    marginLeft: "60px",
  },
  navLinkIcon: {
    height: "18px",
    width: "18px",
  },
  navLinkText: {
    color: "#FEFEFE",
    fontSize: "14px",
    fontWeight: 400,
    display: "block",
    marginLeft: "10px",
    marginRight: "30px",
    "&:hover": { color: "#58F2F0" },
    [theme.breakpoints.down("xs")]: {
      marginRight: "15px",
    },
  },
  actionboxcontainer: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
  },
  actionsBox: {
    // position: "absolute",
    // right: "40px",
    // left:"50%",
    // transform:"translate(-50%)",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    [theme.breakpoints.down("sm")]: {
      // right: "20px",
      // position: "static",
    },
  },
  bellIcon: {
    marginRight: "30px",
    cursor: "pointer",
  },
  iconButton: {
    p: 0,
    border: "1.6px solid #58F2F0",
    height: "34px",
    width: "34px",
  },
  profileAvatar: {
    background: "transparent",
    color: "#58F2F0",
    fontSize: "13px",
    fontWeight: "500",
  },
  socials: {
    flex: 1,
    color: "#FEFEFE",
    display: "flex",
    justifyContent: "end",
  },
  dFlex: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  displaySM: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  icons: {
    display: "flex",
    gap: "32px",
  },
  displayLG: {
    display: "flex",
    marginLeft: "42px",
  },
});

export default footerStyle;
