/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
import { put } from "redux-saga/effects";
import errorHandler from "utils/errorHandler";
import toaster from "utils/toaster";

import {
  projectFetchStart,
  projectFetchSuccess,
  projectFetchFail,
  projectSaveStart,
  projectSaveSuccess,
  projectSaveFail,
  paymentIntentFail,
  paymentIntentStart,
  setupIntentStart,
  setupIntentFail,
  setupIntentSuccesss,
  fetchStripeLinkStart,
  fetchStripeLinkFail,
  fetchStripeLinkSuccess,
  getPaymentSucess,
  getPaymentStart,
  getPaymentFail,
  getProjectUpdatesStart,
  getProjectUpdatesSuccess,
  getProjectUpdatesFail,
  getProjectUpdatesDetaisStart,
  getProjectUpdatesDetailsSuccess,
  getProjectUpdatesDetailsFail,
  sendMessageStart,
  sendMessageSuccess,
  sendMessageFail,
  readMessageStart,
  readMessageSuccess,
  readMessageFail,
  getSellStart,
  getSellFail,
  getSellSucess,
  getWithdrawStart,
  getWithdrawSucess,
  getWithdrawFail,
  getTokenStart,
  getTokenSucess,
  getTokenFail,
  projectListSubmitFail,
  projectListSubmitStart,
  projectListSubmitSuccess,
  getProjectSummaryStart,
  getProjectSummarySuccess,
  getProjectSummaryFail,
  getProjectSummaryTotalsStart,
  getProjectSummaryTotalsSuccess,
  getProjectSummaryTotalsFail,
  getListingHistoryFCCStart,
  getListingHistoryFCCSuccess,
  getListingHistoryFCCFail,
  getListedVCCStart,
  getListedVCCSuccess,
  getListedVCCFail,
  getRetireStart,
  getRetireSuccess,
  getRetireFail,
  getTokenBuyStart,
  getTokenBuySuccess,
  getTokenBuyFail,
  getTokenOwnerDetailStart,
  getTokenOwnerDetailSuccess,
  getTokenOwnerDetailFail,
  updateAccountStatusStart,
  updateAccountStatusSuccess,
  updateAccountStatusFail,
  getFiatTransactionStart,
  getFiatTransactionSuccess,
  getFiatTransactionFail,
  getKYBQuestionsStart,
  getKYBQuestionsSuccess,
  getKYBQuestionsFail,
  submitKYBStart,
  submitKYBSuccess,
  submitKYBFail,
  getUpdateCountsFail,
  getUpdateCountsSuccess,
  getUpdateCountsStart,
  getTemplateFail,
  getTemplateStart,
  getTemplateSuccess,
} from "store/actions";
import { USER_SUBDOMIN } from "constants/env";
import { paymentIntentSuccesss } from "store/actions";
import { uploadProjectDescriptionStart } from "store/actions";
import { uploadProjectDescriptionSuccess } from "store/actions";
import { uploadProjectDescriptionFail } from "store/actions";
import { toast } from "react-toastify";
import { uploadProjectFinalizeStart } from "store/actions";
import { uploadProjectFinalizeSuccess } from "store/actions";
import { uploadProjectFinalizeFail } from "store/actions";
import { uploadProjectMonitoringStart } from "store/actions";
import { fetchMonitoringStart } from "store/actions";
import { fetchMonitoringSuccess } from "store/actions";
import { fetchMonitoringFail } from "store/actions";
import { uploadProjectMonitioringSuccess } from "store/actions";
import { uploadProjectMonitioringFail } from "store/actions";
import { submitProjectFCCStart } from "store/actions";
import { submitProjectFCCSuccess } from "store/actions";
import { submitProjectFCCFail } from "store/actions";
import { GetProjectFCCStart } from "store/actions";
import { GetProjectFCCSuccess } from "store/actions";
import { GetProjectFCCFail } from "store/actions";
import { getListedProjectStart } from "store/actions";
import { getListedProjectSuccess } from "store/actions";
import { getListedProjectFail } from "store/actions";
import { getMintedFCCSuccess } from "store/actions";
import { getMintedFCCFail } from "store/actions";
import { parseUrl } from "utils/helpers";
// import { fetchMonitoring } from "store/actions";

export function delay(delayMS) {
  const promise = new Promise((resolve) => {
    setTimeout(() => resolve(true), delayMS);
  });
  return promise;
}

// project list
export function* projectFetchSaga({ payload }) {
  const { id } = payload;
  yield put(projectFetchStart());
  yield errorHandler({
    endpoint: `/project/${id}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProfileFetchSuccess(response) {
      const { data } = response;
      yield put(projectFetchSuccess(data));
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(projectFetchFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// project save
export function* projectSaveSaga({ payload }) {
  const { requestBody, onSuccess, id, isSave } = payload;
  yield put(projectSaveStart(isSave ? "saving" : "submiting"));
  const url = isSave
    ? "/project/save-project-detail"
    : "/project/submit-project-detail";
  yield errorHandler({
    endpoint: `${url}/${id || ""}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProfileFetchSuccess(response) {
      const { data } = response;
      // yield projectFetchSaga({ payload: { id: data._id } });
      onSuccess(data);
      toast.success(response.message);
      yield put(projectSaveSuccess(data));
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(projectSaveFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "post",
  });
}

// project submit save
export function* projectSubmitSaga({ payload }) {
  const { requestBody, onSuccess } = payload;
  yield put(projectSaveStart());
  yield errorHandler({
    endpoint: `/project/save-project-detail`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProfileFetchSuccess(response) {
      const { data } = response;
      if (onSuccess) {
        onSuccess(data);
      }
      yield put(projectSaveSuccess(data));
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(projectSaveFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "post",
  });
}

//project list submit
export function* projectListSubmitSaga({ payload }) {
  const { id, listId, requestBody } = payload;
  yield put(projectListSubmitStart());
  yield errorHandler({
    // endpoint: `/project/save-project-detail`,
    endpoint: `/project/${id}/list/${listId}/submit-project-list`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProjectListSubmitSuccess(response) {
      const { data } = response;
      yield put(projectListSubmitSuccess(data));
      toaster.success(response.message);
    },
    failHandler: yield function* onProjectListSubmitFail(response) {
      yield put(projectListSubmitFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "post",
  });
}

// Project description

export function* projectDescriptionSaga({ payload }) {
  const { requestBody, id, onSuccess, isSave } = payload;
  yield put(
    uploadProjectDescriptionStart(isSave ? "descriptionSave" : "description")
  );
  const url = isSave
    ? "save-project-description"
    : "submit-project-description";
  yield errorHandler({
    endpoint: `/project/${id}/${url}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      toast.success(response.message);
      onSuccess();
      yield put(uploadProjectDescriptionSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(uploadProjectDescriptionFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    token: true,
    apiType: "post",
  });
}

export function* projectFinalizeSaga({ payload }) {
  const { requestBody, id, onSuccess, isSave } = payload;
  yield put(uploadProjectFinalizeStart(isSave ? "finalizeSave" : "finalize"));
  const url = isSave
    ? "save-project-description-finalization"
    : "submit-project-description-finalization";
  yield errorHandler({
    endpoint: `/project/${id}/${url}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      toast.success(response.message);
      onSuccess();
      yield put(uploadProjectFinalizeSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(uploadProjectFinalizeFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    token: true,
    apiType: "post",
  });
}

export function* projectMonitoringSaga({ payload }) {
  const { requestBody, id, vcc, onSuccess, isSave } = payload;
  yield put(
    uploadProjectMonitoringStart(isSave ? "monitoringSave" : "monitoring")
  );
  const url = isSave ? "save-project-monitoring" : "submit-project-monitoring";
  yield errorHandler({
    endpoint: `/project/${id}/${url}/${vcc || ""}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      toast.success(response.message);
      onSuccess();
      yield put(uploadProjectMonitioringSuccess(data));
      // Dispatch fetchMonitoring action
      // yield put(
      //   fetchMonitoring({
      //     id: id,
      //     notShow: true,
      //     vintageYear: requestBody.get("vintageYear"),
      //   })
      // );
    },
    failHandler: yield function* (response) {
      yield put(uploadProjectMonitioringFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    token: true,
    apiType: "post",
  });
}

export function* fetchMonitoringSaga({ payload }) {
  const { id, notShow, vintageYear } = payload;
  yield put(fetchMonitoringStart());
  yield errorHandler({
    endpoint: `/project/${id}/get-project-monitoring?vintageYear=${vintageYear}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(fetchMonitoringSuccess(data));
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(fetchMonitoringFail(response));
      if (!notShow) toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// Submit Project FCC
export function* submitProjectFCCSaga({ payload }) {
  const { requestBody, id, fcc, onSuccess } = payload;
  yield put(submitProjectFCCStart());
  yield errorHandler({
    endpoint: `/project/${id}/fcc/${fcc}/submit-fcc`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      // const { data } = response;
      toast.success(response.message);
      onSuccess();
      yield put(submitProjectFCCSuccess(requestBody));
    },
    failHandler: yield function* (response) {
      yield put(submitProjectFCCFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    token: true,
    apiType: "post",
  });
}

// Get Project FCC
export function* getProjectFCCSaga({ payload }) {
  const { id, fcc } = payload;
  yield put(GetProjectFCCStart());
  yield errorHandler({
    endpoint: `/project/${id}/fcc/${fcc}/get-project-fcc`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProfileFetchSuccess(response) {
      const { data } = response;
      yield put(GetProjectFCCSuccess(data));
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(GetProjectFCCFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* paymentIntentSage({ payload }) {
  const { requestBody, navigate, extraMeta } = payload;
  yield put(paymentIntentStart());
  yield errorHandler({
    endpoint: `/stripe/buy-token-payment-intent`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onPaymentFetchSuccess(response) {
      const { data } = response;
      yield put(paymentIntentSuccesss(data));
      navigate(
        `/stripe/${data.client_secret}?_fiatTransaction=${data._fiatTransaction}`,
        {
          state: { ...data, ...extraMeta, paymentType: "Payment" },
        }
      );
    },
    failHandler: yield function* onPaymentFetchFail(response) {
      yield put(paymentIntentFail(response));

      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "post",
    //external: true,
  });
}

export function* setupIntentSage() {
  yield put(setupIntentStart());
  yield errorHandler({
    endpoint: `/stripe/create-setup-intent`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onSetupFetchSuccess(response) {
      const { data } = response;

      yield put(setupIntentSuccesss(data));
    },
    failHandler: yield function* onPaymentFetchFail(response) {
      yield put(setupIntentFail(response));

      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "post",
    //external: true,
  });
}

export function* fetchStripeAccountLink({ payload }) {
  const { requestBody } = payload;
  yield put(fetchStripeLinkStart());
  yield errorHandler({
    endpoint: `/stripe/create-account-link`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onSetupFetchSuccess(response) {
      const { data } = response;
      window.open(data.url, "_self");
      yield put(fetchStripeLinkSuccess(data));
    },
    failHandler: yield function* onPaymentFetchFail(response) {
      yield put(fetchStripeLinkFail(response));

      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "post",
    //external: true,
  });
}

export function* registerInenttSaga({ payload }) {
  const { navigate, getRegFee } = payload;
  yield put(paymentIntentStart());
  yield errorHandler({
    endpoint: `/stripe/registration-fee-payment-intent`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onPaymentFetchSuccess(response) {
      const { data } = response;
      yield put(paymentIntentSuccesss(data));
      if (data.isPaid) {
        var parsedUrl = parseUrl();
        window.location = parsedUrl;
      } else {
        navigate(`/stripe/${data.client_secret}`, {
          state: { ...data, paymentType: "Register", fee: getRegFee },
        });
      }
    },
    failHandler: yield function* onPaymentFetchFail(response) {
      yield put(paymentIntentFail(response));

      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "post",
    //external: true,
  });
}

//transactions
//buy
export function* getPaymentIntent({ payload }) {
  yield put(getPaymentStart());
  const { itemsPerPage, startIndex = 1, search = "" } = payload;
  // Check if the search parameter is empty, and provide a default value if it is.
  const searchParam = search ? `&search=${search}` : "";

  let endpoint = `/stripe/get-buyer-fiat-transactions?`;
  endpoint += `sortBy=createdAt&sortValue=-1&startIndex=${startIndex}&itemsPerPage=${itemsPerPage}${searchParam}`;

  // let endpoint = `/stripe/get-buyer-fiat-transactions?sortBy=createdAt&sortValue=-1&search=${search}&startIndex=${startIndex}&itemsPerPage=${itemsPerPage}`;
  // let endpoint = `/stripe/get-payment-intent-list?`;
  // endpoint += `limit=${limit}&lastId=${lastId}`;

  yield errorHandler({
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onPaymentFetchSuccess(response) {
      const { data } = response;
      yield put(getPaymentSucess(data));
    },
    failHandler: yield function* onPaymentFetchFail(response) {
      yield put(getPaymentFail(response));
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

//sell
export function* getSellIntent({ payload }) {
  yield put(getSellStart());

  const { itemsPerPage, startIndex = 1, search = "" } = payload;
  const searchParam = search ? `&search=${search}` : "";
  let endpoint = `/stripe/get-seller-fiat-transactions?`;
  endpoint += `sortBy=createdAt&sortValue=-1&startIndex=${startIndex}&itemsPerPage=${itemsPerPage}${searchParam}`;

  yield errorHandler({
    endpoint,
    // endpoint: `/stripe/get-balance-transaction-list?type=payment`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onSellSuccess(response) {
      const { data } = response;
      yield put(getSellSucess(data));
    },
    failHandler: yield function* onSellFail(response) {
      yield put(getSellFail(response));
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

//withdraw
export function* getWithdrawIntent() {
  yield put(getWithdrawStart());
  yield errorHandler({
    endpoint: `/stripe/get-balance-transaction-list?type=payout`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onWithdrawSuccess(response) {
      const { data } = response;
      yield put(getWithdrawSucess(data));
    },
    failHandler: yield function* onWithdrawFail(response) {
      yield put(getWithdrawFail(response));
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

//token
export function* getTokenIntent(payload) {
  yield put(getTokenStart());

  const { itemsPerPage, startIndex = 1, search = "" } = payload;
  const searchParam = search ? `&search=${search}` : "";
  let endpoint = `/token/get-token-transactions?`;
  endpoint += `sortBy=createdAt&sortValue=-1&startIndex=${startIndex}&itemsPerPage=${itemsPerPage}${searchParam}`;

  yield errorHandler({
    // endpoint: `/stripe/get-payment-intent-list`,
    endpoint,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onTokenSuccess(response) {
      const { data } = response;
      yield put(getTokenSucess(data));
    },
    failHandler: yield function* onTokenFail(response) {
      yield put(getTokenFail(response));
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* getListedProjectSaga({ payload }) {
  const { id, listId } = payload;
  yield put(getListedProjectStart());
  yield errorHandler({
    endpoint: `/project/${id}/list/${listId}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getListedProjectSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getListedProjectFail(response));
      // toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* getMintedFCCSaga({ payload }) {
  const { id, fcc } = payload;
  yield errorHandler({
    endpoint: `/project/${id}/fcc/${fcc}/get-mint-fcc`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getMintedFCCSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getMintedFCCFail(response));
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* getListedUpdateSaga({ payload }) {
  const { id } = payload;
  yield put(getProjectUpdatesStart());
  yield errorHandler({
    endpoint: `/project/${id}/get-conversations`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getProjectUpdatesSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getProjectUpdatesFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* getUpdateDetailsSaga({ payload }) {
  const { id, conversation_id } = payload;
  yield put(getProjectUpdatesDetaisStart());
  yield errorHandler({
    endpoint: `/project/${id}/get-conversation/${conversation_id}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getProjectUpdatesDetailsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getProjectUpdatesDetailsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get",
  });
}

export function* sendMessage({ payload }) {
  const { requestBody, conversation_id, id } = payload;
  yield put(sendMessageStart());
  yield errorHandler({
    endpoint: `/project/${id}/send-message/${conversation_id}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(sendMessageSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(sendMessageFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "post",
  });
}

export function* readMessage({ payload }) {
  const { conversation_id, id } = payload;
  yield put(readMessageStart());
  yield errorHandler({
    endpoint: `/project/${id}/read-message/${conversation_id}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(readMessageSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(readMessageFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "post",
  });
}

export function* getProjectSummarySaga({ payload }) {
  const { _project } = payload; // Assuming _project is the project ID or parameter needed for the endpoint
  yield put(getProjectSummaryStart());
  yield errorHandler({
    endpoint: `/project/${_project}/summary/get-project-summary`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProjectSummarySuccess(response) {
      const { data } = response;
      yield put(getProjectSummarySuccess(data));
    },
    failHandler: yield function* onProjectSummaryFail(response) {
      yield put(getProjectSummaryFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    token: true,
    apiType: "get", // Adjust the method if needed (e.g., "post" or "put")
  });
}

export function* getProjectSummaryTotalsSaga({ payload }) {
  const { _project } = payload;

  // Dispatch the start action
  yield put(getProjectSummaryTotalsStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/project/${_project}/token/get-project-summary-totals`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetProjectSummaryTotalsSuccess(response) {
      const { data } = response;

      // Dispatch the success action with the retrieved data
      yield put(getProjectSummaryTotalsSuccess(data));

      // Display a success toast
      //toaster.success(response.message);
    },
    failHandler: yield function* onGetProjectSummaryTotalsFail(response) {
      // Dispatch the fail action with the error response
      yield put(getProjectSummaryTotalsFail(response));

      // Display an error toast
      toaster.error(response);

      // Perform additional error handling logic if needed
      // ...
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* getListingHistoryFCCSaga({ payload }) {
  const { _project } = payload;

  // Dispatch the start action
  yield put(getListingHistoryFCCStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/project/${_project}/token/get-listing-history-fcc`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetListingHistoryFCCSuccess(response) {
      const { data } = response;

      // Dispatch the success action with the retrieved data
      yield put(getListingHistoryFCCSuccess(data));

      // Display a success toast
    },
    failHandler: yield function* onGetListingHistoryFCCFail(response) {
      // Dispatch the fail action with the error response
      yield put(getListingHistoryFCCFail(response));

      // Display an error toast
      toaster.error(response);

      // Perform additional error handling logic if needed
      // ...
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* getListedVCCSaga({ payload }) {
  const { _project } = payload;

  // Dispatch the start action
  yield put(getListedVCCStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/project/${_project}/token/get-minted-vcc`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetListedVCCSuccess(response) {
      const { data } = response;

      // Dispatch the success action with the retrieved data
      yield put(getListedVCCSuccess(data));

      // Display a success toast
    },
    failHandler: yield function* onGetListedVCCFail(response) {
      // Dispatch the fail action with the error response
      yield put(getListedVCCFail(response));

      // Display an error toast
      toaster.error(response);

      // Perform additional error handling logic if needed
      // ...
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* getTokenBuyTransactionsSaga({ payload }) {
  const { _project, startIndex, itemsPerPage } = payload;

  // Dispatch the start action
  yield put(getTokenBuyStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/project/${_project}/token/get-token-buy-transactions?startIndex=${startIndex}&itemsPerPage=${itemsPerPage}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetTokenBuyTransactionsSuccess(response) {
      // Dispatch the success action with the retrieved data
      //const { data } = response;
      yield put(getTokenBuySuccess(response));

      // Display a success toast or perform additional actions if needed
    },
    failHandler: yield function* onGetTokenBuyTransactionsFail(response) {
      // Dispatch the fail action with the error response
      yield put(getTokenBuyFail(response));

      // Display an error toast or perform additional error handling logic if needed
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* getTokenRetireTransactionsSaga({ payload }) {
  const { _project, startIndex, itemsPerPage } = payload;

  // Dispatch the start action
  yield put(getRetireStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/project/${_project}/token/get-token-retire-transactions?startIndex=${startIndex}&itemsPerPage=${itemsPerPage}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetTokenRetireTransactionsSuccess(
      response
    ) {
      // Dispatch the success action with the retrieved data
      yield put(getRetireSuccess(response));

      // Display a success toast or perform additional actions if needed
    },
    failHandler: yield function* onGetTokenRetireTransactionsFail(response) {
      // Dispatch the fail action with the error response
      yield put(getRetireFail(response));

      // Display an error toast or perform additional error handling logic if needed
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* getTokenOwnerDetailSaga({ payload }) {
  const { _project, _tokenOwner } = payload;

  yield put(getTokenOwnerDetailStart());

  yield errorHandler({
    endpoint: `/project/${_project}/token/get-token-owner-detail/${_tokenOwner}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetTokenOwnerDetailSuccess(response) {
      const { data } = response;
      yield put(getTokenOwnerDetailSuccess(data));
    },
    failHandler: yield function* onGetTokenOwnerDetailFail(response) {
      yield put(getTokenOwnerDetailFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* updateAccountStatusSaga({ payload }) {
  const { status, onSuccess } = payload;

  yield put(updateAccountStatusStart());

  yield errorHandler({
    endpoint: `/stripe/update-account-status`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onUpdateAccountStatusSuccess(response) {
      // You can handle the success logic here if needed
      yield put(updateAccountStatusSuccess(response));
      onSuccess();
      // Display a success toast
      toaster.success(response.message);
    },
    failHandler: yield function* onUpdateAccountStatusFail(response) {
      yield put(updateAccountStatusFail(response));

      // Display an error toast
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { status }, // Pass the status in the request payload
    token: true,
    apiType: "post",
  });
}

export function* getFiatTransactionSaga({ payload }) {
  const { _fiatTransaction, onSuccess } = payload; // Assuming you need a parameter for the endpoint

  // Dispatch the start action
  yield put(getFiatTransactionStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/stripe/get-fiat-transaction/${_fiatTransaction}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onGetFiatTransactionSuccess(response) {
      if (onSuccess) {
        onSuccess(response?.data);
      }
      // Dispatch the success action with the retrieved data
      yield put(getFiatTransactionSuccess(response.data));

      // Display a success toast or perform additional actions if needed
    },
    failHandler: yield function* onGetFiatTransactionFail(response) {
      // Dispatch the fail action with the error response
      yield put(getFiatTransactionFail(response));

      // Display an error toast or perform additional error handling logic if needed
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* getKYBQuestions() {
  // Dispatch the start action
  yield put(getKYBQuestionsStart());

  // Use the errorHandler to handle API calls with success and failure scenarios
  yield errorHandler({
    endpoint: `/profile/get-kyb-questions`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* ongetKYBQuestionsSuccess(response) {
      // Dispatch the success action with the retrieved data
      yield put(getKYBQuestionsSuccess(response.data));

      // Display a success toast or perform additional actions if needed
    },
    failHandler: yield function* onGetFiatTransactionFail(response) {
      // Dispatch the fail action with the error response
      yield put(getKYBQuestionsFail(response));

      // Display an error toast or perform additional error handling logic if needed
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

export function* submitKYB({ payload }) {
  const { requestBody, onSuccess, isSave } = payload;
  yield put(submitKYBStart());
  const url = isSave ? "save-kyb" : "submit-kyb";
  yield errorHandler({
    endpoint: `/profile/${url}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data } = response;
      toast.success(response.message);
      onSuccess();
      yield put(submitKYBSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(submitKYBFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    token: true,
    apiType: "post",
  });
}

//get update counts
export function* getUpdateCountsSaga({ payload }) {
  const { _project } = payload;

  yield put(getUpdateCountsStart());

  yield errorHandler({
    endpoint: `/project/${_project}/get-updates-count`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* ongetUpdateCountsSuccess(response) {
      const { data } = response;
      yield put(getUpdateCountsSuccess(data));
    },
    failHandler: yield function* ongetUpdateCountsFail(response) {
      yield put(getUpdateCountsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

//get template
export function* getTemplateSaga({ payload }) {
  const { _project, phase } = payload;

  yield put(getTemplateStart());

  yield errorHandler({
    endpoint: `/project/${_project}/get-template/${phase}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* ongetTemplateSuccess(response) {
      const { data } = response;
      yield put(getTemplateSuccess(data));
    },
    failHandler: yield function* ongetTemplateFail(response) {
      yield put(getTemplateFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}
