import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="main-content-container">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
