/**
 * The base typography styles for the Material Kit 2 React.
 * You can add new typography style using this file.
 * You can customized the typography styles for the entire Material Kit 2 React using thie file.
 */

// Material Kit 2 React Base Styles
import colors from "assets/theme/base/colors";

// Material Kit 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { black, text } = colors;

const baseProperties = {
  fontFamily: "Roboto",
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 900,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
  fontSize2XL: pxToRem(24),
  fontSize3XL: pxToRem(30),
};

const baseHeadingProperties = {
  color: black.main,
  fontWeight: baseProperties.fontWeightBold,
};
const baseHeadingProperties2 = {
  color: black.main,
  fontWeight: baseProperties.fontWeightExtraBold,
};
const baseHeadingProperties3 = {
  color: black.main,
  fontWeight: baseProperties.fontWeightMedium,
};
const baseHeadingProperties4 = {
  color: black.main,
  fontWeight: baseProperties.fontWeightMedium,
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  color: black.main,
  fontWeight: baseProperties.fontWeightLight,
  lineHeight: 1.2,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightSemiBold: baseProperties.fontWeightSemiBold,
  fontWeightBold: baseProperties.fontWeightBold,
  fontWeightExtraBold: baseProperties.fontWeightExtraBold,

  h1: {
    fontFamily: baseProperties.fontFamily1,
    fontSize: pxToRem(48),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },
  // Main Heading
  h2: {
    fontFamily: baseProperties.fontFamily1,
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },
  h2ExtraHeavy: {
    fontFamily: baseProperties.fontFamily1,
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    fontWeight: `${baseProperties.fontWeightExtraBold} !important`,
    ...baseHeadingProperties,
  },


  h3: {
    fontFamily: baseProperties.fontFamily1,
    fontSize: pxToRem(30),
    lineHeight: 1.375,
    ...baseHeadingProperties2,
  },

  h4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h5: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h5Heavy: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize2XL,
    fontWeight: `${baseProperties.fontWeightBold} !important`,
    lineHeight: 1.2,
    ...baseHeadingProperties,
  },

  h5ExtraHeavy: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize2XL,
    fontWeight: `${baseProperties.fontWeightExtraBold} !important`,
    lineHeight: 1.2,
    ...baseHeadingProperties,
  },

  h5LargeExtraHeavy: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    fontWeight: `${baseProperties.fontWeightExtraBold} !important`,
    lineHeight: 1.2,
    ...baseHeadingProperties,
  },

  h6: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },
  h7: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(14),
    lineHeight: 1.625,
    ...baseHeadingProperties3,
  },
  h8: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProperties3,
  },
  h9: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(14),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },
  h10: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(13),
    lineHeight: 1.625,
    ...baseHeadingProperties4,
  },
  p1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(13),
    lineHeight: 1.625,
    fontWeightRegular: baseProperties.fontWeightRegular,
  },
  p3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    fontWeightRegular: baseProperties.fontWeightRegular,
  },
  p2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(13),
    lineHeight: 1.625,
    fontWeightRegular: baseProperties.fontWeightRegular,
  },
  p5: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(14),
    lineHeight: 1.625,
    fontWeightRegular: baseProperties.fontWeightRegular,
  },
  p4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    fontWeight: baseProperties.fontWeightBold,
  },

  link: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
    color: "#58F2F0",
  },

  link2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.6,
    color: "#58F2F0",
    cursor: "pointer",
  },

  // Heading description
  description: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
    color: `${text.grey} !important`,
  },

  description1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
    color: "#4F4F4F",
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.625,
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6,
  },

  subtitleMedium: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.2,
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6,
  },

  genericRegular: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.2,
  },

  genericRegularLHlg: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.8,
  },

  genericMedium: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.2,
  },

  genericMixed1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.2,
  },

  genericMixed2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.2,
  },

  genericMixed3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.2,
  },

  genericMixed4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.2,
  },

  genericMixed5: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize2XL,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.2,
  },

  genericMixed4LightBlack: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.2,
    color: `${black.light} !important`,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.5,
    textTransform: "none",
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.25,
  },

  d1: {
    fontSize: pxToRem(80),
    ...baseDisplayProperties,
  },

  d2: {
    fontSize: pxToRem(72),
    ...baseDisplayProperties,
  },

  d3: {
    fontSize: pxToRem(64),
    ...baseDisplayProperties,
  },

  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties,
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    "2xl": baseProperties.fontSize2XL,
    "3xl": baseProperties.fontSize3XL,
  },

  // link: {
  //   fontFamily: baseProperties.fontFamily,
  //   fontSize: baseProperties.fontSizeSM,
  //   fontWeight: baseProperties.fontWeightRegular,
  //   lineHeight: 1.6,
  //   color: "#58F2F0",
  // },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },
};

export default typography;
