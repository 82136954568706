/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
import { put, call } from "redux-saga/effects";
import errorHandler from "utils/errorHandler";
import toaster from "utils/toaster";

import {
  loginSuccess,
  loginFail,
  loginStart,
  // eslint-disable-next-line no-unused-vars
  logout,
  logoutStart,
  logoutSuccess,
  logoutFail,
  loginAuthenticationStart,
  loginAuthenticationSuccess,
  loginAuthenticationFail,
  signupSuccess,
  signupFail,
  signupStart,
  sendOTPSuccess,
  resendOtpSuccess,
  sendOTPFail,
  resendOtpFail,
  sendOTPStart,
  resendOtpStart,
  verifyOTPSuccess,
  verifyOTPFail,
  verifyOTPStart,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  resetPasswordAuthStart,
  resetPasswordAuthSuccess,
  resetPasswordAuthFail,
  checkUserError,
  checkResetPasswordLinkValidityStart,
  checkResetPasswordLinkValiditySuccess,
  checkResetPasswordLinkValidityFail,
  getLocationStart,
  getLocationSuccess,
  getLocationFail,
  resetApp,
  profileFetchStart,
  profileFetchSuccess,
  profileFetchFail,
  resendEmailStart,
  resendEmailSuccess,
  resendEmailFail,
  emailVerificationStart,
  emailVerificationSuccess,
  emailVerificationFail,
} from "store/actions";
import axios from "axios";
import { USER_SUBDOMIN, AUTH_SUBDOMIN } from "constants/env";

let cancelReq = "";

const axiosMain = axios.create({
  baseURL: `${USER_SUBDOMIN}/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});

export function delay(delayMS) {
  const promise = new Promise((resolve) => {
    setTimeout(() => resolve(true), delayMS);
  });
  return promise;
}
// Login
export function* loginSaga({ payload }) {
  const { requestBody, navigate } = payload;
  yield put(loginStart());
  yield errorHandler({
    endpoint: `/auth/login`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onLoginSuccess(response) {
      yield put(signupSuccess(response.data));
      navigate("/otp", { state: { isLogin: true } });
    },
    failHandler: yield function* onLoginFail(response) {
      yield put(loginFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    apiType: "post",
  });
}

// profile details
export function* profileFetchSaga({ payload }) {
  const { onSuccess, onFail, onStart } = payload;
  yield put(profileFetchStart());
  onStart && onStart();
  yield errorHandler({
    endpoint: `/profile/get-user`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProfileFetchSuccess(response) {
      const { data } = response;
      yield put(profileFetchSuccess(data));
      onSuccess && onSuccess();
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(profileFetchFail(response));
      toaster.error(response);
      onFail && onFail();
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

export function* profileUpdateSaga({ payload }) {
  const { requestBody } = payload;
  yield put(profileFetchStart());
  yield errorHandler({
    endpoint: `/profile/update-profile`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onProfileFetchSuccess(response) {
      const { data } = response;
      yield put(profileFetchSuccess(data));
    },
    failHandler: yield function* onProfileFetchFail(response) {
      yield put(profileFetchFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: { ...requestBody },
    token: true,
    apiType: "put",
  });
}

// Logout
export function* logoutSaga() {
  yield put(logoutStart());
  yield errorHandler({
    endpoint: `/auth/logout/`,
    successHandler: yield function* onLogoutSuccess(response) {
      const { data } = response;
      localStorage.clear();
      yield put(logoutSuccess(data));
      window.location = "/login";
    },
    failHandler: yield function* onLogoutFail(response) {
      yield put(logoutFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "post",
  });
}

export function* loginAuthenticationSaga({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { requestBody, setErr } = payload;
  yield put(loginAuthenticationStart());
  yield errorHandler({
    endpoint: `/auth/verify-otp`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onLoginAuthenticationSuccess(response) {
      const { data, message } = response;
      yield put(loginAuthenticationSuccess(data));
      toaster.success(message);
      setErr("");
      // Storing data in local storage
      yield call(
        [localStorage, "setItem"],
        "authToken",
        response.data.authToken
      );
      yield call([localStorage, "setItem"], "toaster-type", "success");
      if (data.userType == "developer") {
        localStorage.setItem("isDeveloper", "developer");
        if (data.registrationFee.isPaid) {
          // window.location = "/dashboard-listing";
          window.location = "/marketplace";
        } else {
          window.location = "/verification-stepper";
        }
      } else {
        if (data.stripe.account.status == "verified") {
          // window.location = "/investor-dashboard-listing";
          window.location = "/marketplace";
        } else {
          window.location = "/verification-stepper";
        }
      }
    },
    failHandler: yield function* onSendOTPFail(response) {
      yield put(loginAuthenticationFail(response));
      toaster.error(response);
      setErr("err");
    },
    failHandlerType: "CUSTOM",
    payload: {
      ...requestBody,
    },
    apiType: "post",
  });
}

// Mobile/Email send otp
export function* sendOTPSaga(action) {
  yield put(sendOTPStart());
  const { requestBody, handleSuccess, handleFail } = action.payload;
  yield errorHandler({
    endpoint: `/auth/sendOTP`,
    subDomin: AUTH_SUBDOMIN,
    successHandler: yield function* onSendOTPSuccess(response) {
      const { data, msg } = response;
      yield put(sendOTPSuccess(data));
      handleSuccess && handleSuccess();
      toaster.success(msg);
    },
    failHandler: yield function* onSendOTPFail(response) {
      yield put(sendOTPFail(response.msg));
      handleFail && handleFail();
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "post",
  });
}

// Resend OTP
export function* resendOTPSaga(action) {
  yield put(resendOtpStart());
  const { requestBody } = action.payload;
  yield errorHandler({
    endpoint: `/auth/resend-otp`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onresendOTPSuccess(response) {
      const { data, message } = response;
      yield put(resendOtpSuccess(data));
      toaster.success(message);
    },
    failHandler: yield function* onresendOTPFail(response) {
      yield put(resendOtpFail(response.msg));
      toaster.error(response.msg);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "post",
  });
}

// Resend email verification
export function* resendEmailVerificationSaga(action) {
  yield put(resendEmailStart());
  const { requestBody } = action.payload;
  yield errorHandler({
    endpoint: `/user/signupUpdate`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onResendEmailSuccess(response) {
      const { data, msg } = response;
      yield put(resendEmailSuccess(data));
      toaster.success(msg);
    },
    failHandler: yield function* onResendEmailFail(response) {
      yield put(resendEmailFail(response.msg));
      toaster.error(response.msg);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    token: true,
    apiType: "put",
  });
}

// Signup saga
export function* signupSaga(action) {
  const { requestBody, navigate } = action.payload;
  yield put(signupStart());
  yield errorHandler({
    endpoint: `/auth/register`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onSignupSuccess(response) {
      yield put(
        signupSuccess({
          email: response.data,
          isEmailVerified: true,
        })
      );
      navigate("/otp", { state: { isLogin: false } });
    },
    failHandler: yield function* onSignupFail(response) {
      yield put(signupFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "post",
  });
}

// email Verification
export function* emailVerificationSaga(action) {
  yield put(emailVerificationStart());
  const { requestBody, handleSuccess } = action.payload;
  yield errorHandler({
    endpoint: `/user/emailVerification`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      handleSuccess();
      const { data } = response;
      yield put(emailVerificationSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(emailVerificationFail(response));
      const errMsg = yield localStorage.getItem("toaster-message");
      toaster.error(errMsg);
    },

    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "post",
  });
}

// Mobile/Email verify otp
export function* verifyOTPSaga(action) {
  const { requestBody, handleSuccess } = action.payload;
  yield put(verifyOTPStart());
  yield errorHandler({
    endpoint: `/auth/verifyOTP`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onverifyOTPSuccess(response) {
      handleSuccess();
      const { data, msg } = response;

      yield put(verifyOTPSuccess(data));
      toaster.success(msg);
    },
    failHandler: yield function* onverifyOTPFail(response) {
      yield put(verifyOTPFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    // request body can either be for email or phone
    // Example EMAIL Payload
    //   {
    //     "channel": "email",
    //     "email": "something@solulab.co",
    //     "code": "649191"
    //   }
    // Example SMS Payload
    //   {
    //     "channel": "sms",
    //     "countryCode": "+91",
    //     "mobileNumber": 9999999999,
    //     "code": "050495"
    //   }
    payload: requestBody,
    apiType: "post",
  });
}

// Forgot Password
export function* forgotPasswordSaga(action) {
  yield put(forgotPasswordStart());
  const { requestBody, handleSuccess } = action.payload;
  yield errorHandler({
    endpoint: `/auth/forgot-password`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      handleSuccess?.();
      const { data } = response;
      yield put(forgotPasswordSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(forgotPasswordFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "post",
  });
}

// Reset Password
export function* resetPasswordSaga(action) {
  yield put(resetPasswordStart());
  const { requestBody, handleSuccess, token } = action.payload;
  yield errorHandler({
    endpoint: `/auth/reset-password/?token=${token}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      handleSuccess?.();
      const { data, msg } = response;
      yield put(resetPasswordSuccess(data));
      toaster.success(msg);
    },
    failHandler: yield function* (response) {
      yield put(resetPasswordFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "patch",
  });
}

// Reset Password when logged in
export function* resetPasswordAuthSaga(action) {
  yield put(resetPasswordAuthStart());
  // eslint-disable-next-line no-unused-vars
  const { requestBody, handleSuccess, navigate } = action.payload;
  yield errorHandler({
    endpoint: `/profile/change-password`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* (response) {
      const { data, message } = response;
      yield put(resetPasswordAuthSuccess(data));
      yield put(resetApp());
      yield call(profileFetchSaga);
      // window.location.reload();
      toaster.success(message);
    },
    failHandler: yield function* (response) {
      yield put(resetPasswordAuthFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: requestBody,
    apiType: "post",
    token: true,
  });
}

export function* userCheckSaga(action) {
  const { name, value } = action.payload;
  if (cancelReq) {
    cancelReq.cancel();
  }
  cancelReq = axios.CancelToken.source();
  try {
    const response = yield axios
      .post(
        "/auth/checkUser",
        { [name]: value },
        { cancelToken: cancelReq.token }
      )
      .then((res) => res)
      .catch((er) => er);
    if (response.status === 200) {
      yield put(checkUserError({ name, error: response.data.msg }));
    } else {
      yield put(checkUserError({ name, error: "" }));
    }
  } catch (err) {
    // console.log(err);
  }
}

export function* authenticationValidatorSaga() {
  yield put(loginStart());
  const token = yield localStorage.getItem("authToken");
  if (!token) {
    // yield put(logout()); // logout action
    // alert("You are not logged in");
  } else {
    yield put(loginSuccess({ token }));
  }
}

// Check Reset Password Link validity
export function* checkResetPasswordLinkValidity(action) {
  yield put(checkResetPasswordLinkValidityStart());
  yield errorHandler({
    endpoint: `/auth/checkResetPasswordLink`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(checkResetPasswordLinkValiditySuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(checkResetPasswordLinkValidityFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: action.payload,
    apiType: "post",
  });
}

// Check list of all the countries, states and city
export function* getLocations(action) {
  const { url, type, reqType, body } = action.payload;
  yield put(getLocationStart());
  const response = yield axiosMain[reqType](url, body)
    .then((res) => res)
    .catch((er) => er);

  let { data } = response;
  if (response.status === 200) {
    if (url === "countries/iso") {
      data = data.data;
    }
    yield put(getLocationSuccess({ data, locationType: type }));
  } else {
    yield put(
      getLocationFail({ locationType: type, msg: "Something went wrong!" })
    );
  }
}
