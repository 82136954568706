import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";

//static imports
import MKBox from "components/custom/MKBox";
import MKTypography from "components/custom/MKTypography";
import Tick from "assets/images/Tick.svg";
import Close from "assets/images/close.png";
import { styles } from "./styles";

const StatusModal = ({
  open,
  handleClose,
  classes,
  title,
  status = "succeeded",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      aria-labelledby="responsive-dialog-title"
    >
      <MKBox display="flex" justifyContent="flex-end" className={classes.mkBox}>
        <CloseIcon className={classes.closeIconRight} onClick={handleClose} />
      </MKBox>

      <Box p={3}>
        <MKBox display="flex" justifyContent="center" alignItems="center" p={2}>
          {status !== "succeeded" ? (
            <img
              src={Tick}
              className={classes.checkedCircleStyle1}
              alt="green_checked_icon"
            />
          ) : (
            <img src={Close} style={{ width: "80px" }} />
          )}
        </MKBox>
        <MKTypography
          variant="h3"
          align="center"
          className={classes.modalTitle}
        >
          {title}
        </MKTypography>
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(StatusModal);
