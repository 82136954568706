import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Button } from "@mui/material";
import moment from "moment";

// Static imports
import { HeaderLogo as Logo, Menu1, Home, Menu3, Bell } from "constants/assets";
import MKTypography from "components/custom/MKTypography";
import styles from "./styles";
import CustomConfirmationModal from "components/Modal/CustomConfirmationModal";
import MKButton from "components/custom/MKButton";
import { useDispatch } from "react-redux";
import { profileFetch } from "store/actions";
import { socket, connectSocket, SOCKET_EVENTS } from "constants/socketsManager";
import toaster from "utils/toaster";

const settings = ["Profile", "Transaction History", "Logout"];

const Header = ({ classes }) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state?.auth?.userData?.userType);
  const userData = useSelector((state) => state?.auth?.userData);
  const pages = [
    {
      name: "Dashboard",
      icon: Menu1,
      link:
        userType === "developer"
          ? "/dashboard-listing"
          : "/investor-dashboard-listing",
    },
    {
      name: "Marketplace",
      icon: Home,
      link: "/marketplace",
    },
    {
      name: "Support",
      icon: Menu3,
      link: "https://infrablockshelp.zendesk.com/hc/en-gb",
      external: true,
    },
  ];
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openlogoutModal, setOpenLogoutModal] = useState(false);
  const navigate = useNavigate();
  const active = true;
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [notificationList, setNotificationList] = useState([]);

  const canAccessDashboard =
    userData?.userType === "developer" && userData?.registrationFee?.isPaid;

  const canAccessInvestorDashboard =
    userData?.userType === "investor" &&
    // !userData?.registrationFee?.isPaid &&
    userData?.stripe?.account?.status === "verified";

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseUserMenu = (item) => {
    setAnchorElUser(null);
    if (!item) return;
    if (item === "Logout") {
      setOpenLogoutModal((prev) => !prev);
    } else if (item === "Transaction History") {
      if (canAccessDashboard || canAccessInvestorDashboard) {
        navigate("/transactions");
      } else {
        toaster?.error(
          "Please continue with your account set up to get full access on the platform"
        );
      }
    } else {
      navigate("/profile");
    }
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    location.reload();
  };

  const handleOptions = (link) => {
    // if (!link) return;
    // navigate(link);
    if (!link) return;

    // Check if the user has the necessary permissions before navigating
    if (canAccessDashboard || canAccessInvestorDashboard) {
      // Navigate only if the user has permission
      navigate(link);
    } else {
      // If the user doesn't have permission, you can show a message or perform another action
      toaster?.error(
        "Please continue with your account set up to get full access on the platform"
      );
    }
  };

  useEffect(() => {
    dispatch(profileFetch({}));
  }, []);

  useEffect(() => {
    // socket connection function
    connectSocket();

    // Emit a 'JOIN' event
    socket.emit(
      SOCKET_EVENTS.JOIN,
      { startIndex: 1, itemsPerPage: 1000 },
      (response) => {
        console.log(response, "resss socket");
      }
    );

    //On a 'NOTIFICATION' event
    socket.on(SOCKET_EVENTS.NOTIFICATION, (data) => {
      setNotificationList(data);
    });

    // might use in future
    // On a 'NOTIFICATIONS_LIST' event
    // socket.on(
    //   SOCKET_EVENTS.NOTIFICATIONS_LIST,
    //   { startIndex: 1, itemsPerPage: 1000 },
    //   (data) => {
    //     console.log(data, "NOTIFICATIONS_LIST socket");
    //   }
    // );
  }, []);

  const handleViewAll = () => {
    handleCloseNotification();
    navigate("/notifications");
  };

  const handleRead = (notification) => {
    console.log("read", notification, "handleRead socket");
    socket.emit(
      SOCKET_EVENTS.MARK_AS_READ,
      {
        _notification: notification?._id,
        isMarkAll: false,
        itemsPerPage: 1000,
      },
      (data) => {
        console.log("mark as read", data, "socket");
      }
    );
  };

  const handleAllRead = () => {
    console.log("all mark read socket");
    socket.emit(
      SOCKET_EVENTS.MARK_ALL_AS_READ,
      {
        isMarkAll: true,
      },
      (data) => {
        console.log("mark as read", data, "socket");
      }
    );
  };

  return (
    <AppBar
      position="static"
      className={`${classes.appBar} ${classes.headerFix}`}
    >
      <Container className={`${classes.headerContainer}`}>
        <Toolbar disableGutters className={classes.height100}>
          <img
            src={Logo}
            className={classes.logo}
            alt="Logo"
            // onClick={() => navigate("/verification-stepper")}
            onClick={() =>
              window.open("https://www.infrablockstechnologies.com")
            }
          />
          {active && (
            <Box className={`${classes.navLinksBox} ${classes.displayLG}`}>
              {pages.map((page, index) =>
                page?.external ? (
                  <a
                    key={index}
                    href={page.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box key={page.name} className={classes.dFlex}>
                      <img
                        src={page.icon}
                        alt="menu1"
                        className={classes.navLinkIcon}
                      />
                      <MKTypography
                        variant="h7"
                        fontWeight="medium"
                        color="white"
                        className={classes.navLinkText}
                      >
                        {page.name}
                      </MKTypography>
                    </Box>
                  </a>
                ) : (
                  <Box
                    key={page.name}
                    className={classes.dFlex}
                    onClick={() => handleOptions(page.link)}
                  >
                    <img
                      src={page.icon}
                      alt="menu1"
                      className={classes.navLinkIcon}
                    />
                    <MKTypography
                      variant="h7"
                      fontWeight="medium"
                      color="white"
                      className={classes.navLinkText}
                    >
                      {page.name}
                    </MKTypography>
                  </Box>
                )
              )}
            </Box>
          )}

          {active && (
            <Box className={classes.actionsBox}>
              {notificationList?.unreadCounts !== 0 && (
                <Typography textAlign="center" className={classes.count}>
                  {notificationList.unreadCounts || ""}
                </Typography>
              )}
              <Tooltip title="Notifications">
                <img
                  src={Bell}
                  onClick={handleOpenNotification}
                  alt="notification"
                  className={classes.bellIcon}
                />
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElNotification}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNotification)}
                onClose={() => handleCloseNotification()}
                PaperProps={{
                  style: {
                    padding: "10px 10px 0px 10px",
                    width: "450px",
                    maxHeight: "300px",
                    overflow: "auto",
                  },
                }}
              >
                {!notificationList?.data?.data ||
                notificationList?.data?.data?.length === 0 ||
                notificationList?.unreadCounts === 0 ? (
                  <Typography fontSize="14px" textAlign="center">
                    No new notification available
                  </Typography>
                ) : (
                  notificationList?.data?.data?.map(
                    // eslint-disable-next-line no-unused-vars
                    (notification, index) =>
                      notification?.isRead === false && (
                        <MenuItem
                          disableRipple
                          className={`${classes.menuItem} ${classes.noRipple}`}
                          // key={index}
                          key={notification._id}
                        >
                          <Box>
                            <Tooltip title={notification.description}>
                              <Typography
                                // textAlign="center"
                                style={{ whiteSpace: "wrap" }}
                                className={classes.notificationItem}
                              >
                                {notification.title}
                              </Typography>
                            </Tooltip>
                            <Typography className={classes.time}>
                              {moment
                                .utc(notification.createdAt)
                                .local()
                                .format("HH:mm")}
                            </Typography>
                          </Box>
                          <Button
                            disableRipple
                            onClick={() => handleRead(notification)}
                            className={classes.read}
                          >
                            Mark as read
                          </Button>
                        </MenuItem>
                      )
                  )
                )}
                {/* 
                {!notificationList?.data?.data ||
                notificationList?.data?.data?.length === 0 ||
                notificationList?.unreadCounts === 0 ? null : ( */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="10px"
                  position="sticky"
                  bottom={0}
                  backgroundColor="white !important"
                >
                  <MKButton
                    variant="outlined"
                    className={classes.markAll}
                    onClick={handleViewAll}
                  >
                    View all
                  </MKButton>
                  <MKButton
                    className={classes.markAll}
                    disabled={
                      !notificationList?.data?.data ||
                      notificationList?.data?.data?.length === 0 ||
                      notificationList?.unreadCounts === 0
                    }
                    onClick={handleAllRead}
                  >
                    Mark all read
                  </MKButton>
                </Box>
                {/* )} */}
              </Menu>
              <Tooltip title="Profile">
                <IconButton
                  onClick={handleOpenUserMenu}
                  className={classes.iconButton}
                >
                  <Avatar alt="AB" className={classes.profileAvatar}>
                    <PermIdentityIcon fontSize="medium" />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu()}
              >
                {settings.map((setting, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <Typography fontSize="1rem" textAlign="center">
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
        <CustomConfirmationModal
          data={{
            title: "Are you sure you want to log out?",
            success: "Logout",
            cancel: "Cancel",
          }}
          handleSuccess={handleLogout}
          handleClose={() => setOpenLogoutModal((prev) => !prev)}
          open={openlogoutModal}
        />
      </Container>
    </AppBar>
  );
};
export default withStyles(styles)(Header);
