import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Logo } from "constants/assets";
import MKBox from "components/custom/MKBox";
import styles from "./style.js";
import { authurl } from "constants/assets.js";
import { useLocation } from "react-router-dom";
import Footer from "components/Footer/index.js";
import HeaderNoAuth from "components/HeaderNoAuth/index.js";

const AuthLayout = ({ classes, children }) => {
  const { pathname } = useLocation();
  if (pathname === "/privacy" || pathname === "/terms") {
    return (
      <>
        <HeaderNoAuth />
        {children}
        <Footer />
      </>
    );
  }

  return (
    <MKBox className={classes.mainSection}>
      <MKBox className={classes.section1}>
        <MKBox>
          <img src={Logo} alt="Logo" />
        </MKBox>
        {children}
      </MKBox>
      <MKBox className={classes.section2}>
        <div className={classes.backgroundImageContainer}>
          <img
            src={authurl}
            alt="Background"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </MKBox>
    </MKBox>
  );
};

export default withStyles(styles)(AuthLayout);
