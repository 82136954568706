/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
import { put } from "redux-saga/effects";
import errorHandler from "utils/errorHandler";
import toaster from "utils/toaster";

import {
  getCountriesStart,
  getCountriesSuccess,
  getCountriesFail,
  getStatesStart,
  getStatesSuccess,
  getStatesFail,
  getMethodologiesStart,
  getMethodologiesSuccess,
  getMethodologiesFail,
  getStandardProgramsStart,
  getStandardProgramsSuccess,
  getStandardProgramsFail,
  getStandardsStart,
  getStandardsSuccess,
  getStandardsFail,
  getSectorsStart,
  getSectorsSuccess,
  getSectorsFail,
  getVintageYearsFail,
  getVintageYearsStart,
  getVintageYearsSuccess,
  getProjectVintageYearsStart,
  getProjectVintageYearsSuccess,
  getProjectVintageYearsFail,
} from "store/actions";
import { USER_SUBDOMIN } from "constants/env";
import { getListedVintageYearsStart } from "store/actions";
import { getListedVintageYearsSuccess } from "store/actions";
import { getListedVintageYearsFail } from "store/actions";

export function delay(delayMS) {
  const promise = new Promise((resolve) => {
    setTimeout(() => resolve(true), delayMS);
  });
  return promise;
}

// countries
export function* getCountriesSaga() {
  yield put(getCountriesStart());
  yield errorHandler({
    endpoint: `/auth/get-countries`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onCountriesSuccess(response) {
      const { data } = response;
      yield put(getCountriesSuccess(data));
    },
    failHandler: yield function* onCountriesFail(response) {
      yield put(getCountriesFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// states
export function* getStatesSaga({ payload }) {
  yield put(getStatesStart());
  const { value } = payload;
  yield errorHandler({
    endpoint: `/auth/get-states?countryCode=${value}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onCountriesSuccess(response) {
      const { data } = response;
      yield put(getStatesSuccess(data));
    },
    failHandler: yield function* onCountriesFail(response) {
      yield put(getStatesFail(response));
      // toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// methodologies
export function* getMethodologiesSaga({ payload }) {
  yield put(getMethodologiesStart());
  const { value } = payload;
  yield errorHandler({
    // endpoint: `/project/get-methodologies?_standard=64da23f7465a938acd5ba8ae`,
    endpoint: `/project/get-methodologies?_standard=${value}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onMethodologiesSuccess(response) {
      const { data } = response;
      yield put(getMethodologiesSuccess(data));
    },
    failHandler: yield function* onMethodologiesFail(response) {
      yield put(getMethodologiesFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// standard programs
export function* getStandardProgramsSaga({ payload }) {
  yield put(getStandardProgramsStart());
  const { value } = payload;
  yield errorHandler({
    endpoint: `/project/get-standard-programs?_standard=${value}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onStandardProgramsSuccess(response) {
      const { data } = response;
      yield put(getStandardProgramsSuccess(data));
    },
    failHandler: yield function* onStandardProgramsFail(response) {
      yield put(getStandardProgramsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// standards
export function* getStandardsSaga() {
  yield put(getStandardsStart());

  yield errorHandler({
    endpoint: `/project/get-standards`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onStandardsSuccess(response) {
      const { data } = response;
      yield put(getStandardsSuccess(data));
    },
    failHandler: yield function* onStandardsFail(response) {
      yield put(getStandardsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// sectors
export function* getSectorsSaga({ payload }) {
  yield put(getSectorsStart());
  const { value } = payload;
  yield errorHandler({
    // endpoint: `/project/get-sectors?_standard=64da23f7465a938acd5ba8ac`,
    endpoint: `/project/get-sectors?_standard=${value}`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onStandardsSuccess(response) {
      const { data } = response;
      yield put(getSectorsSuccess(data));
    },
    failHandler: yield function* onStandardsFail(response) {
      yield put(getSectorsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// vintage years
export function* getVintageYearsSaga() {
  yield put(getVintageYearsStart());
  yield errorHandler({
    endpoint: `/marketplace/get-vintage-years`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* onVintageYearsSuccess(response) {
      const { data } = response;
      yield put(getVintageYearsSuccess(data));
    },
    failHandler: yield function* onVintageYearsFail(response) {
      yield put(getVintageYearsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {},
    token: true,
    apiType: "get",
  });
}

// project vintage years
export function* getProjectVintageYearsSaga({ payload }) {
  const { _project } = payload;
  yield put(getProjectVintageYearsStart());
  yield errorHandler({
    endpoint: `/project/${_project}/get-project-vintage-years`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* ongetVintageYearsSuccess(response) {
      const { data } = response;
      yield put(getProjectVintageYearsSuccess(data));
    },
    failHandler: yield function* ongetVintageYearsFail(response) {
      yield put(getProjectVintageYearsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}

// listed vintage years
export function* getListedVintageYearsSaga() {
  yield put(getListedVintageYearsStart());
  yield errorHandler({
    endpoint: `/dashboard/investor/get-listed-vintage-years`,
    subDomin: USER_SUBDOMIN,
    successHandler: yield function* ongetVintageYearsSuccess(response) {
      const { data } = response;
      yield put(getListedVintageYearsSuccess(data));
    },
    failHandler: yield function* ongetVintageYearsFail(response) {
      yield put(getListedVintageYearsFail(response));
      toaster.error(response);
    },
    failHandlerType: "CUSTOM",
    payload: {}, // Add any necessary payload for the API call
    token: true, // Indicate if authentication token is required
    apiType: "get", // Specify the HTTP method (get, post, etc.)
  });
}
