/* eslint-disable no-lonely-if */
import { put } from "redux-saga/effects";
import axiosMain from "./axiosMain";

const createBaseUrl = (subDomin, external = false) => {
  if (external) {
    return "";
  }
  return `${subDomin}/api/v1`;
};

export default function* errorHandler({
  endpoint,
  successHandler,
  failHandler,
  failHandlerType = "",
  payload = {},
  apiType = "",
  token = false,
  isLogoutCall = false,
  subDomin = "",
  external = false,
  // showToast = "",
}) {
  if (apiType.trim() === "") {
    throw new Error("apiType is require");
  }

  try {
    let response;
    if (!token) {
      if (apiType === "get") {
        response = yield axiosMain.get(createBaseUrl(subDomin) + endpoint);
      } else if (apiType === "post") {
        response = yield axiosMain.post(
          createBaseUrl(subDomin, external) + endpoint,
          payload
        );
      } else if (apiType === "put") {
        response = yield axiosMain.put(
          createBaseUrl(subDomin) + endpoint,
          payload
        );
      } else if (apiType === "patch") {
        response = yield axiosMain.patch(
          createBaseUrl(subDomin) + endpoint,
          payload
        );
      } else if (apiType === "delete") {
        response = yield axiosMain.delete(createBaseUrl(subDomin) + endpoint);
      }
    } else {
      const authToken = yield localStorage.getItem("authToken");
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          // "ngrok-skip-browser-warning": "6024",
          "ngrok-skip-browser-warning": true,
        },
      };
      // eslint-disable-next-line no-lonely-if
      if (apiType === "get") {
        response = yield axiosMain.get(
          createBaseUrl(subDomin) + endpoint,
          config
        );
      } else if (apiType === "post") {
        response = yield axiosMain.post(
          createBaseUrl(subDomin, external) + endpoint,
          payload,
          config
        );
      } else if (apiType === "put") {
        response = yield axiosMain.put(
          createBaseUrl(subDomin) + endpoint,
          payload,
          config
        );
      } else if (apiType === "patch") {
        response = yield axiosMain.patch(
          createBaseUrl(subDomin) + endpoint,
          payload,
          config
        );
      } else if (apiType === "delete") {
        response = yield axiosMain.delete(
          createBaseUrl(subDomin) + endpoint,
          config
        );
      }
    }

    if (
      response &&
      (response.status === 200 || response.status === 201) &&
      response.data
    ) {
      yield successHandler(response.data);

      // showToast && successToast(response.data);
    } else if (response !== undefined && response.status !== undefined) {
      if (
        response.data.error !== undefined &&
        response.data.error !== "" &&
        typeof response.data.error === "string"
      ) {
        if (failHandlerType === "CUSTOM") {
          yield failHandler(response.data.error);
        } else {
          yield put(failHandler(response.data.error));
        }
      } else {
        if (failHandlerType === "CUSTOM") {
          yield failHandler("Server error! Please try again.");
        } else {
          yield put(failHandler("Server error! Please try again."));
        }
      }
    } else {
      if (failHandlerType === "CUSTOM") {
        yield failHandler("Something went wrong! Please try again.");
      } else {
        yield put(failHandler("Something went wrong! Please try again."));
      }
    }
  } catch (error) {
    console.log("error", error);
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error?.response?.status === 400) {
        if (failHandlerType === "CUSTOM") {
          yield failHandler(error.response.data.error);
        } else {
          yield put(failHandler(error.response.data.error));
        }
      } else if (error?.response?.status === 401) {
        if (isLogoutCall) {
          // successHandler({});
        } else {
          // yield put(logout({ logoutType: "manual" }));
          yield failHandler(
            error.response.data.error || error.response.data.message
          );
        }
      } else if (
        error.response.data.error !== undefined &&
        error.response.data.error !== "" &&
        typeof error.response.data.error === "string"
      ) {
        if (failHandlerType === "CUSTOM") {
          yield failHandler(error.response.data.error);
        } else {
          yield put(failHandler(error.response.data.error));
        }
      } else {
        if (failHandlerType === "CUSTOM") {
          yield failHandler("Server error! Please try again.");
        } else {
          yield put(failHandler("Server error! Please try again."));
        }
      }
    } else {
      if (failHandlerType === "CUSTOM") {
        yield failHandler("Something went wrong! Please try again.");
      } else {
        yield put(failHandler("Something went wrong! Please try again."));
      }
    }
  }
}
