import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@material-ui/styles";
// static imports
import MKButton from "components/custom/MKButton";
import MKBox from "components/custom/MKBox";
import MKTypography from "components/custom/MKTypography";
import infoImg from '../../assets/images/error_Info_Icon.png'
import { styles } from "./styles";

const CustomConfirmationModal = ({ open, handleClose, classes, data, handleSuccess, image}) => {
  const { title, subtitle } = data;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      aria-labelledby="responsive-dialog-title"
      maxWidth="670px"
    >
      <MKBox display="flex" justifyContent="right" className={classes.mkBox}>
        <CloseIcon
          className={classes.closeIconRight}
          onClick={handleClose}
        />
      </MKBox>
      {image === "info" && <Box display="flex" justifyContent="center"><img className={classes.modalImg} src={infoImg} /></Box>}
      <DialogTitle display="flex" justifyContent="center" className={classes.dialogTitle} mt={3}>
        <MKTypography
          variant="h3"
          align="center"
          className={classes.modalTitle}
        >
          {title}
        </MKTypography>
      </DialogTitle>
      <DialogContent display="flex" justifyContent="center" p={2} className={classes.dialogContent}>
        <MKTypography
          variant="body2"
          className={classes.subtitle}
        >
          {subtitle || ""}
        </MKTypography>
      </DialogContent>
      <MKBox display="flex" justifyContent="center" className={classes.modalBottomButton} gap={2} my={2}>
        <MKButton
          variant="outlined"
          className={classes.mkConfirmationButtonCancel}
          color="primary"
          size="medium"
          onClick={handleClose}
        >
          {data.cancel}
        </MKButton>
        <MKButton variant="contained" className={classes.mkConfirmationButtonSuccess} color="error" size="medium" onClick={handleSuccess}>
          {data.success}
        </MKButton>
      </MKBox>
    </Dialog>
  );
}

export default withStyles(styles)(CustomConfirmationModal)