import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

// Static imports
import { HeaderLogo as Logo } from "constants/assets";
import styles from "./styles";
const Header = ({ classes }) => {
  const navigate = useNavigate();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container className={classes.headerContainer}>
        <Toolbar disableGutters className={classes.height100}>
          <img
            src={Logo}
            className={classes.logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default withStyles(styles)(Header);
