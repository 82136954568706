export const RESET_APP = "RESET_APP";

// Login

export const LOGIN_SAGA = "LOGIN_SAGA";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Login

export const LOGIN_VERIFY_SAGA = "LOGIN_VERIFY_SAGA";
export const LOGIN_VERIFY_START = "LOGIN_VERIFY_START";
export const LOGIN_VERIFY_SUCCESS = "LOGIN_VERIFY_SUCCESS";
export const LOGIN_VERIFY_FAIL = "LOGIN_VERIFY_FAIL";

// Profile

export const PROFILE_FETCH_SAGA = "PROFILE_FETCH_SAGA";
export const PROFILE_UPDATE_SAGA = "PROFILE_UPDATE_SAGA";
export const PROFILE_FETCH_START = "PROFILE_FETCH_START";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_FETCH_FAIL = "PROFILE_FETCH_FAIL";

// Login authentication
export const AUTH_LOGIN_SAGA = "AUTH_LOGIN_SAGA";
export const AUTH_LOGIN_START = "AUTH_LOGIN_START";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

// Signup

export const SIGNUP_SAGA = "SIGNUP_SAGA";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

// Resend email verification

export const RESEND_EMAIL_SAGA = "RESEND_EMAIL_SAGA";
export const RESEND_EMAIL_START = "RESEND_EMAIL_START";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAIL = "RESEND_EMAIL_FAIL";

// Email verification

export const EMAIL_VERIFICATION_SAGA = "EMAIL_VERIFICATION_SAGA";
export const EMAIL_VERIFICATION_START = "EMAIL_VERIFICATION_START";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL";

// Mobile number Otp send

export const PHONE_SEND_OTP_SAGA = "PHONE_SEND_OTP_SAGA";
export const PHONE_SEND_OTP_START = "PHONE_SEND_OTP_START";
export const PHONE_SEND_OTP_SUCCESS = "PHONE_SEND_OTP_SUCCESS";
export const PHONE_SEND_OTP_FAIL = "PHONE_SEND_OTP_FAIL";

// Mobile number Otp verify

export const PHONE_VERIFY_OTP_SAGA = "PHONE_VERIFY_OTP_SAGA";
export const PHONE_VERIFY_OTP_START = "PHONE_VERIFY_OTP_START";
export const PHONE_VERIFY_OTP_SUCCESS = "PHONE_VERIFY_OTP_SUCCESS";
export const PHONE_VERIFY_OTP_FAIL = "PHONE_VERIFY_OTP_FAIL";

// Forgot password

export const FORGOT_PASSWORD_SAGA = "FORGOT_PASSWORD_SAGA";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

// Reset Password

export const RESET_PASSWORD_SAGA = "RESET_PASSWORD_SAGA";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// Resend Otp

export const RESEND_OTP_SAGA = "RESEND_OTP_SAGA";
export const RESEND_OTP_START = "RESEND_OTP_START";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";

// Reset Password when logged in

export const RESET_PASSWORD_AUTH_SAGA = "RESET_PASSWORD_AUTH_SAGA";
export const RESET_PASSWORD_AUTH_START = "RESET_PASSWORD_AUTH_START";
export const RESET_PASSWORD_AUTH_SUCCESS = "RESET_PASSWORD_AUTH_SUCCESS";
export const RESET_PASSWORD_AUTH_FAIL = "RESET_PASSWORD_AUTH_FAIL";

// Logout

export const LOGOUT_SAGA = "LOGOUT_SAGA";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const AUTHENTICATION_VALIDATOR = "AUTHENTICATION_VALIDATOR";
export const CHECK_USER = "CHECK_USER";
export const CHECK_USER_ERROR = "CHECK_USER_ERROR";

// Check forget password link is expired or not

export const CHECK_RESET_PASSWORD_LINK_VALIDITY_SAGA = "CHECK_RESET_PASSWORD_LINK_VALIDITY_SAGA";
export const CHECK_RESET_PASSWORD_LINK_VALIDITY_START = "CHECK_RESET_PASSWORD_LINK_VALIDITY_START";
export const CHECK_RESET_PASSWORD_LINK_VALIDITY_SUCCESS =
  "CHECK_RESET_PASSWORD_LINK_VALIDITY_SUCCESS";
export const CHECK_RESET_PASSWORD_LINK_VALIDITY_FAIL = "CHECK_RESET_PASSWORD_LINK_VALIDITY_FAIL";

// Location for country, state, city

export const GET_LOCATION_SAGA = "GET_LOCATION_SAGA";
export const GET_LOCATION_START = "GET_LOCATION_START";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAIL = "GET_LOCATION_FAIL";
