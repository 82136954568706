import * as actionLabels from "../../actionLabels";

// Project fetch

export const projectFetchStart = () => ({
  type: actionLabels.PROJECT_FETCH_START,
});

export const projectFetch = (payload) => ({
  type: actionLabels.PROJECT_FETCH_SAGA,
  payload,
});

export const projectFetchSuccess = (payload) => ({
  type: actionLabels.PROJECT_FETCH_SUCCESS,
  payload,
});

export const projectFetchFail = (payload) => ({
  type: actionLabels.PROJECT_FETCH_FAIL,
  payload,
});

// Project Save

export const projectSaveStart = (payload) => ({
  type: actionLabels.PROJECT_SAVE_START,
  payload,
});

export const projectSave = (payload) => ({
  type: actionLabels.PROJECT_SAVE_SAGA,
  payload,
});

export const projectSaveSuccess = (payload) => ({
  type: actionLabels.PROJECT_SAVE_SUCCESS,
  payload,
});

export const projectSaveFail = (payload) => ({
  type: actionLabels.PROJECT_SAVE_FAIL,
  payload,
});

// Project list submit

export const projectListSubmitStart = (payload) => ({
  type: actionLabels.PROJECT_LIST_SUBMIT_START,
  payload,
});

export const projectListSubmit = (payload) => ({
  type: actionLabels.PROJECT_LIST_SUBMIT_SAGA,
  payload,
});

export const projectListSubmitSuccess = (payload) => ({
  type: actionLabels.PROJECT_LIST_SUBMIT_SUCCESS,
  payload,
});

export const projectListSubmitFail = (payload) => ({
  type: actionLabels.PROJECT_LIST_SUBMIT_FAIL,
  payload,
});

//stripe payment Inteent
export const paymentIntentStart = () => ({
  type: actionLabels.PAYMENT_INTENT_START,
});

export const paymentIntentFetch = (payload) => ({
  type: actionLabels.PAYMENT_INTENT_SAGA,
  payload,
});

export const paymentIntentSuccesss = (payload) => ({
  type: actionLabels.PAYMENT_INTENT_SUCCESS,
  payload,
});

export const paymentIntentFail = (payload) => ({
  type: actionLabels.PAYMENT_INTENT_FAIL,
  payload,
});

//stripe payment Intent
export const setupIntentStart = () => ({
  type: actionLabels.SETUP_INTENT_START,
});

export const setupIntentSuccesss = (payload) => ({
  type: actionLabels.SETUP_INTENT_SUCCESS,
  payload,
});

export const setupIntentFail = (payload) => ({
  type: actionLabels.SETUP_INTENT_FAIL,
  payload,
});

export const stripeSetupIntent = (payload) => ({
  type: actionLabels.SETUP_INTENT_SAGA,
  payload,
});

//stripe get account link
export const fetchStripeLinkStart = () => ({
  type: actionLabels.FETCH_STRIPE_LINK_START,
});

export const fetchStripeLinkSuccess = (payload) => ({
  type: actionLabels.FETCH_STRIPE_LINK_SUCCESS,
  payload,
});

export const fetchStripeLinkFail = (payload) => ({
  type: actionLabels.FETCH_STRIPE_LINK_FAIL,
  payload,
});

export const fetchStripeLink = (payload) => ({
  type: actionLabels.FETCH_STRIPE_LINK_SAGA,
  payload,
});

export const registerPayment = (payload) => ({
  type: actionLabels.REGISTER_INTENT_SAGA,
  payload,
});

//transactions
//buy
export const getPaymentStart = (payload) => ({
  type: actionLabels.GET_PAYMENT_INTENT_START,
  payload,
});
export const getPaymentSucess = (payload) => ({
  type: actionLabels.GET_PAYMENT_INTENT_SUCCESS,
  payload,
});

export const getPaymentFail = (payload) => ({
  type: actionLabels.GET_PAYMENT_INTENT_FAIL,
  payload,
});

export const getPaymentIntent = (payload) => ({
  type: actionLabels.GET_PAYMENT_INTENT_SAGA,
  payload,
  // Project document description
});
// Project document description

//sell
export const getSellStart = (payload) => ({
  type: actionLabels.GET_SELL_TRANSACTIONS_START,
  payload,
});
export const getSellSucess = (payload) => ({
  type: actionLabels.GET_SELL_TRANSACTIONS_SUCCESS,
  payload,
});

export const getSellFail = (payload) => ({
  type: actionLabels.GET_SELL_TRANSACTIONS_FAIL,
  payload,
});

export const getSellIntent = (payload) => ({
  type: actionLabels.GET_SELL_TRANSACTIONS_SAGA,
  payload,
});

//withdraw
export const getWithdrawStart = (payload) => ({
  type: actionLabels.GET_WITHDRAW_TRANSACTIONS_START,
  payload,
});
export const getWithdrawSucess = (payload) => ({
  type: actionLabels.GET_WITHDRAW_TRANSACTIONS_SUCCESS,
  payload,
});

export const getWithdrawFail = (payload) => ({
  type: actionLabels.GET_WITHDRAW_TRANSACTIONS_FAIL,
  payload,
});

export const getWithdrawIntent = (payload) => ({
  type: actionLabels.GET_WITHDRAW_TRANSACTIONS_SAGA,
  payload,
});

//token
export const getTokenStart = (payload) => ({
  type: actionLabels.GET_TOKEN_TRANSACTIONS_START,
  payload,
});
export const getTokenSucess = (payload) => ({
  type: actionLabels.GET_TOKEN_TRANSACTIONS_SUCCESS,
  payload,
});

export const getTokenFail = (payload) => ({
  type: actionLabels.GET_TOKEN_TRANSACTIONS_FAIL,
  payload,
});

export const getTokenIntent = (payload) => ({
  type: actionLabels.GET_TOKEN_TRANSACTIONS_SAGA,
  payload,
});

export const uploadProjectDescriptionStart = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_START,
  payload,
});

export const uploadProjectDescription = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_SAGA,
  payload,
});

export const uploadProjectDescriptionSuccess = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_SUCCESS,
  payload,
});

export const uploadProjectDescriptionFail = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_FAIL,
  payload,
});

// Project finalaize description

export const uploadProjectFinalizeStart = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_FINALIZATION_START,
  payload,
});

export const uploadProjectFinalize = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_FINALIZATION_SAGA,
  payload,
});

export const uploadProjectFinalizeSuccess = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_FINALIZATION_SUCCESS,
  payload,
});

export const uploadProjectFinalizeFail = (payload) => ({
  type: actionLabels.PROJECT_SAVE_DESC_FINALIZATION_FAIL,
  payload,
});

// Project monitoring save

export const uploadProjectMonitoringStart = (payload) => ({
  type: actionLabels.MONITORING_SAVE_START,
  payload,
});

export const uploadProjectMonitioring = (payload) => ({
  type: actionLabels.MONITORING_SAVE_SAGA,
  payload,
});

export const uploadProjectMonitioringSuccess = (payload) => ({
  type: actionLabels.MONITORING_SAVE_SUCCESS,
  payload,
});

export const uploadProjectMonitioringFail = (payload) => ({
  type: actionLabels.MONITORING_SAVE_FAIL,
  payload,
});

// Fetch project monitoring

export const fetchMonitoring = (payload) => ({
  type: actionLabels.MONITORING_FETCH_SAGA,
  payload,
});

export const fetchMonitoringStart = () => ({
  type: actionLabels.MONITORING_FETCH_START,
});

export const fetchMonitoringSuccess = (payload) => ({
  type: actionLabels.MONITORING_FETCH_SUCCESS,
  payload,
});

export const fetchMonitoringFail = (payload) => ({
  type: actionLabels.MONITORING_FETCH_FAIL,
  payload,
});

// Submit Project FCC

export const submitProjectFCC = (payload) => ({
  type: actionLabels.SUBMIT_PROJECT_FCC_SAGA,
  payload,
});

export const submitProjectFCCStart = () => ({
  type: actionLabels.SUBMIT_PROJECT_FCC_START,
});

export const submitProjectFCCSuccess = (payload) => ({
  type: actionLabels.SUBMIT_PROJECT_FCC_SUCCESS,
  payload,
});

export const submitProjectFCCFail = (payload) => ({
  type: actionLabels.SUBMIT_PROJECT_FCC_FAIL,
  payload,
});

// Get Project FCC

export const GetProjectFCC = (payload) => ({
  type: actionLabels.GET_PROJECT_FCC_SAGA,
  payload,
});

export const GetProjectFCCStart = () => ({
  type: actionLabels.GET_PROJECT_FCC_START,
});

export const GetProjectFCCSuccess = (payload) => ({
  type: actionLabels.GET_PROJECT_FCC_SUCCESS,
  payload,
});

export const GetProjectFCCFail = (payload) => ({
  type: actionLabels.GET_PROJECT_FCC_FAIL,
  payload,
});

// Get listed project details

export const getListedProject = (payload) => ({
  type: actionLabels.GET_LIST_PROJECT_SAGA,
  payload,
});

export const getListedProjectStart = (payload) => ({
  type: actionLabels.GET_LIST_PROJECT_START,
  payload,
});

export const getListedProjectSuccess = (payload) => ({
  type: actionLabels.GET_LIST_PROJECT_SUCCESS,
  payload,
});

export const getListedProjectFail = (payload) => ({
  type: actionLabels.GET_LIST_PROJECT_FAIL,
  payload,
});

//get Project Summary

export const getProjectSummary = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_SAGA,
  payload,
});

export const getProjectSummaryStart = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_START,
  payload,
});

export const getProjectSummarySuccess = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_SUCCESS,
  payload,
});

export const getProjectSummaryFail = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_FAIL,
  payload,
});

// Mint FCC

export const mintFCCSuccess = () => ({
  type: actionLabels.MINT_FCC_SUCCESS,
});

// Get Minted FCC

export const getMintedFCC = (payload) => ({
  type: actionLabels.GET_MINTED_FCC_SAGA,
  payload,
});

export const getMintedFCCStart = () => ({
  type: actionLabels.GET_MINTED_FCC_START,
});

export const getMintedFCCSuccess = (payload) => ({
  type: actionLabels.GET_MINTED_FCC_SUCCESS,
  payload,
});

export const getMintedFCCFail = (payload) => ({
  type: actionLabels.GET_MINTED_FCC_FAIL,
  payload,
});
//project updates

export const getProjectUpdates = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_SAGA,
  payload,
});

export const getProjectUpdatesStart = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_START,
  payload,
});

export const getProjectUpdatesSuccess = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_SUCCESS,
  payload,
});

export const getProjectUpdatesFail = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_FAIL,
  payload,
});

//project update details
export const getProjectUpdatesDetails = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_DETAILS_SAGA,
  payload,
});

export const getProjectUpdatesDetaisStart = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_DETAILS_START,
  payload,
});

export const getProjectUpdatesDetailsSuccess = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_DETAILS_SUCCESS,
  payload,
});

export const getProjectUpdatesDetailsFail = (payload) => ({
  type: actionLabels.GET_CONVERSATIONS_DETAILS_FAIL,
  payload,
});

//project update send message

export const sendMessage = (payload) => ({
  type: actionLabels.SEND_MESSAGE__SAGA,
  payload,
});

export const sendMessageStart = (payload) => ({
  type: actionLabels.SEND_MESSAGE_START,
  payload,
});

export const sendMessageSuccess = (payload) => ({
  type: actionLabels.SEND_MESSAGE_SUCCESS,
  payload,
});

export const sendMessageFail = (payload) => ({
  type: actionLabels.SEND_MESSAGE_FAIL,
  payload,
});

//project update read message

export const readMessage = (payload) => ({
  type: actionLabels.READ_MESSAGE__SAGA,
  payload,
});

export const readMessageStart = (payload) => ({
  type: actionLabels.READ_MESSAGE_START,
  payload,
});

export const readMessageSuccess = (payload) => ({
  type: actionLabels.READ_MESSAGE_SUCCESS,
  payload,
});

export const readMessageFail = (payload) => ({
  type: actionLabels.READ_MESSAGE_FAIL,
  payload,
});

export const clearProject = () => ({
  type: actionLabels.CLEAR_PROJECT,
});

// Project Summary Totals
export const getProjectSummaryTotal = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_TOTALS_SAGA,
  payload,
});
export const getProjectSummaryTotalsStart = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_TOTALS_START,
  payload,
});

export const getProjectSummaryTotalsSuccess = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_TOTALS_SUCCESS,
  payload,
});

export const getProjectSummaryTotalsFail = (payload) => ({
  type: actionLabels.GET_PROJECT_SUMMARY_TOTALS_FAIL,
  payload,
});

// Listing History FCC
export const getListingHistoryFCC = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_FCC_SAGA,
  payload,
});
export const getListingHistoryFCCStart = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_FCC_START,
  payload,
});

export const getListingHistoryFCCSuccess = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_FCC_SUCCESS,
  payload,
});

export const getListingHistoryFCCFail = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_FCC_FAIL,
  payload,
});

// Listed VCC
export const getListedVCC = (payload) => ({
  type: actionLabels.GET_LISTED_VCC_SAGA,
  payload,
});
export const getListedVCCStart = (payload) => ({
  type: actionLabels.GET_LISTED_VCC_START,
  payload,
});

export const getListedVCCSuccess = (payload) => ({
  type: actionLabels.GET_LISTED_VCC_SUCCESS,
  payload,
});

export const getListedVCCFail = (payload) => ({
  type: actionLabels.GET_LISTED_VCC_FAIL,
  payload,
});

// Listed VCC History
export const getListedHistoryVCC = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_VCC_SAGA,
  payload,
});
export const getListedHistoryVCCStart = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_VCC_START,
  payload,
});

export const getListedHistoryVCCSuccess = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_VCC_SUCCESS,
  payload,
});

export const getListedHistoryVCCVCCFail = (payload) => ({
  type: actionLabels.GET_LISTING_HISTORY_VCC_FAIL,
  payload,
});

// Listed Retire Token
export const getRetireSage = (payload) => ({
  type: actionLabels.GET_RETIRE_SAGA,
  payload,
});
export const getRetireStart = (payload) => ({
  type: actionLabels.GET_RETIRE_START,
  payload,
});

export const getRetireSuccess = (payload) => ({
  type: actionLabels.GET_RETIRE_SUCCESS,
  payload,
});

export const getRetireFail = (payload) => ({
  type: actionLabels.GET_RETIRE_FAIL,
  payload,
});

// Listed buy

export const getTokenBuySaga = (payload) => ({
  type: actionLabels.GET_TOKEN_BUY_SAGA,
  payload,
});
export const getTokenBuyStart = (payload) => ({
  type: actionLabels.GET_TOKEN_BUY_START,
  payload,
});

export const getTokenBuySuccess = (payload) => ({
  type: actionLabels.GET_TOKEN_BUY_SUCCESS,
  payload,
});

export const getTokenBuyFail = (payload) => ({
  type: actionLabels.GET_TOKEN_BUY_FAIL,
  payload,
});

export const getTokenOwnerDetail = (payload) => ({
  type: actionLabels.GET_TOKEN_OWNER_DETAIL_SAGA,
  payload,
});
export const getTokenOwnerDetailStart = (payload) => ({
  type: actionLabels.GET_TOKEN_OWNER_DETAIL_START,
  payload,
});

export const getTokenOwnerDetailSuccess = (payload) => ({
  type: actionLabels.GET_TOKEN_OWNER_DETAIL_SUCCESS,
  payload,
});

export const getTokenOwnerDetailFail = (payload) => ({
  type: actionLabels.GET_TOKEN_OWNER_DETAIL_FAIL,
  payload,
});

//update stripe account status
export const updateAccountStatus = (payload) => ({
  type: actionLabels.UPDATE_ACCOUNT_STATUS_SAGA,
  payload,
});
export const updateAccountStatusStart = (payload) => ({
  type: actionLabels.UPDATE_ACCOUNT_STATUS_START,
  payload,
});

export const updateAccountStatusSuccess = (payload) => ({
  type: actionLabels.UPDATE_ACCOUNT_STATUS_SUCCESS,
  payload,
});

export const updateAccountStatusFail = (payload) => ({
  type: actionLabels.UPDATE_ACCOUNT_STATUS_FAIL,
  payload,
});

//get fiat transaction info

export const getFiatTransaction = (payload) => ({
  type: actionLabels.GET_FIAT_TRANSACTION_SAGA,
  payload,
});
export const getFiatTransactionStart = (payload) => ({
  type: actionLabels.GET_FIAT_TRANSACTION_START,
  payload,
});

export const getFiatTransactionSuccess = (payload) => ({
  type: actionLabels.GET_FIAT_TRANSACTION_SUCCESS,
  payload,
});

export const getFiatTransactionFail = (payload) => ({
  type: actionLabels.GET_FIAT_TRANSACTION_FAIL,
  payload,
});

//get kyb questions
export const getKYBQuestions = (payload) => ({
  type: actionLabels.GET_KYB_QUESTIONS_SAGA,
  payload,
});
export const getKYBQuestionsStart = (payload) => ({
  type: actionLabels.GET_KYB_QUESTIONS_START,
  payload,
});

export const getKYBQuestionsSuccess = (payload) => ({
  type: actionLabels.GET_KYB_QUESTIONS_SUCCESS,
  payload,
});

export const getKYBQuestionsFail = (payload) => ({
  type: actionLabels.GET_KYB_QUESTIONS_FAIL,
  payload,
});

//submit KYB
export const submitKYB = (payload) => ({
  type: actionLabels.SUBMIT_KYB_SAGA,
  payload,
});
export const submitKYBStart = (payload) => ({
  type: actionLabels.SUBMIT_KYB_START,
  payload,
});

export const submitKYBSuccess = (payload) => ({
  type: actionLabels.SUBMIT_KYB_SUCCESS,
  payload,
});

export const submitKYBFail = (payload) => ({
  type: actionLabels.SUBMIT_KYB_FAIL,
  payload,
});

//get update counts
export const getUpdateCounts = (payload) => {
  return {
    type: actionLabels.GET_UPDATE_COUNTS_SAGA,
    payload,
  };
};
export const getUpdateCountsStart = (payload) => ({
  type: actionLabels.GET_UPDATE_COUNTS_START,
  payload,
});

export const getUpdateCountsSuccess = (payload) => ({
  type: actionLabels.GET_UPDATE_COUNTS_SUCCESS,
  payload,
});

export const getUpdateCountsFail = (payload) => ({
  type: actionLabels.GET_UPDATE_COUNTS_FAIL,
  payload,
});

//get template
export const getTemplate = (payload) => ({
  type: actionLabels.GET_TEMPLATE_SAGA,
  payload,
});
export const getTemplateStart = (payload) => ({
  type: actionLabels.GET_TEMPLATE_START,
  payload,
});

export const getTemplateSuccess = (payload) => ({
  type: actionLabels.GET_TEMPLATE_SUCCESS,
  payload,
});

export const getTemplateFail = (payload) => ({
  type: actionLabels.GET_TEMPLATE_FAIL,
  payload,
});
