import * as actionLabels from "../../actionLabels";

// countries

export const getCountriesStart = () => ({
  type: actionLabels.GET_COUNTRIES_START,
});

export const getCountries = () => ({
  type: actionLabels.GET_COUNTRIES_SAGA,
});

export const getCountriesSuccess = (payload) => ({
  type: actionLabels.GET_COUNTRIES_SUCCESS,
  payload,
});

export const getCountriesFail = (payload) => ({
  type: actionLabels.GET_COUNTRIES_FAIL,
  payload,
});

// states

export const getStatesStart = () => ({
  type: actionLabels.GET_STATES_START,
});

export const getStates = (payload) => ({
  type: actionLabels.GET_STATES_SAGA,
  payload,
});

export const getStatesSuccess = (payload) => ({
  type: actionLabels.GET_STATES_SUCCESS,
  payload,
});

export const getStatesFail = (payload) => ({
  type: actionLabels.GET_STATES_FAIL,
  payload,
});

// methodologies

export const getMethodologiesStart = () => ({
  type: actionLabels.GET_METHODOLOGIES_START,
});

export const getMethodologies = (payload) => ({
  type: actionLabels.GET_METHODOLOGIES_SAGA,
  payload,
});

export const getMethodologiesSuccess = (payload) => ({
  type: actionLabels.GET_METHODOLOGIES_SUCCESS,
  payload,
});

export const getMethodologiesFail = (payload) => ({
  type: actionLabels.GET_METHODOLOGIES_FAIL,
  payload,
});

// standard programs

export const getStandardProgramsStart = () => ({
  type: actionLabels.GET_STANDARD_PROGRAMS_START,
});

export const getStandardPrograms = (payload) => ({
  type: actionLabels.GET_STANDARD_PROGRAMS_SAGA,
  payload,
});

export const getStandardProgramsSuccess = (payload) => ({
  type: actionLabels.GET_STANDARD_PROGRAMS_SUCCESS,
  payload,
});

export const getStandardProgramsFail = (payload) => ({
  type: actionLabels.GET_STANDARD_PROGRAMS_FAIL,
  payload,
});

// standards

export const getStandardsStart = () => ({
  type: actionLabels.GET_STANDARDS_START,
});

export const getStandards = () => ({
  type: actionLabels.GET_STANDARDS_SAGA,
});

export const getStandardsSuccess = (payload) => ({
  type: actionLabels.GET_STANDARDS_SUCCESS,
  payload,
});

export const getStandardsFail = (payload) => ({
  type: actionLabels.GET_STANDARDS_FAIL,
  payload,
});

// sectors

export const getSectorsStart = () => ({
  type: actionLabels.GET_SECTORS_START,
});

export const getSectors = (payload) => ({
  type: actionLabels.GET_SECTORS_SAGA,
  payload,
});

export const getSectorsSuccess = (payload) => ({
  type: actionLabels.GET_SECTORS_SUCCESS,
  payload,
});

export const getSectorsFail = (payload) => ({
  type: actionLabels.GET_SECTORS_FAIL,
  payload,
});

// vintage years

export const getVintageYearsStart = () => ({
  type: actionLabels.GET_VINTAGE_YEARS_START,
});

export const getVintageYears = () => ({
  type: actionLabels.GET_VINTAGE_YEARS_SAGA,
});

export const getVintageYearsSuccess = (payload) => ({
  type: actionLabels.GET_VINTAGE_YEARS_SUCCESS,
  payload,
});

export const getVintageYearsFail = (payload) => ({
  type: actionLabels.GET_VINTAGE_YEARS_FAIL,
  payload,
});

// project vintage years

export const getProjectVintageYearsStart = (payload) => ({
  type: actionLabels.GET_PROJECT_VINTAGE_YEARS_START,
  payload,
});

export const getProjectVintageYears = (payload) => ({
  type: actionLabels.GET_PROJECT_VINTAGE_YEARS_SAGA,
  payload,
});

export const getProjectVintageYearsSuccess = (payload) => ({
  type: actionLabels.GET_PROJECT_VINTAGE_YEARS_SUCCESS,
  payload,
});

export const getProjectVintageYearsFail = (payload) => ({
  type: actionLabels.GET_PROJECT_VINTAGE_YEARS_FAIL,
  payload,
});

// listed vintage years

export const getListedVintageYearsStart = () => ({
  type: actionLabels.GET_LISTED_VINTAGE_YEARS_START,
});

export const getListedVintageYears = () => ({
  type: actionLabels.GET_LISTED_VINTAGE_YEARS_SAGA,
});

export const getListedVintageYearsSuccess = (payload) => ({
  type: actionLabels.GET_LISTED_VINTAGE_YEARS_SUCCESS,
  payload,
});

export const getListedVintageYearsFail = (payload) => ({
  type: actionLabels.GET_LISTED_VINTAGE_YEARS_FAIL,
  payload,
});
