// import { authurl } from "constants/assets";

const headerStyle = (theme) => ({
  mainSection: {
    display: "flex",
    height: "100vh",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  section1: {
    flex: "50% 1",
    padding: "12px 20px",
    overflow: "auto",
    margin: 0,
  },
  section2: {
    // background: `url(${authurl})`,
    // width: "100%",
    // height: "100%",
    // backgroundPosition: "right",
    // backgroundSize: "cover",
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    flex: "50% 1",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  backgroundImageContainer: {
    width: "100%",
    height: "100%",
  },
});

export default headerStyle;
